import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Cookie from 'js-cookie';
import { AllAnalyticsCookies } from './AllAnalyticsCookies';
import LocalStorageAlt from './localStorageAlt';
import { canUseCookie, getTld } from './util';
export default class CookieWrapper {
  constructor(disableCookiePersistence) {
    this.cookieLike = canUseCookie(disableCookiePersistence) ? Cookie : new LocalStorageAlt();
  }
  set(name, value, options) {
    // no-op
    if (!AllAnalyticsCookies[name]) {
      // eslint-disable-next-line no-console
      console.warn(`Unknown cookie key ${name} provided, not set.`);
      return;
    }
    return this.cookieLike.set(AllAnalyticsCookies[name].getKey(), value, _objectSpread(_objectSpread({}, AllAnalyticsCookies[name].getAttributes()), {}, {
      // Function is memoized so we only calculate this once per page load
      domain: getTld()
    }, options));
  }
  get(name) {
    if (!AllAnalyticsCookies[name]) {
      // eslint-disable-next-line no-console
      console.warn(`Unknown cookie key ${name} provided, not fetched.`);
      return;
    }
    return this.cookieLike.get(AllAnalyticsCookies[name].getKey());
  }
  remove(name, options) {
    if (!AllAnalyticsCookies[name]) {
      // eslint-disable-next-line no-console
      console.warn(`Unknown cookie key ${name} provided, not removed.`);
      return;
    }
    this.cookieLike.remove(AllAnalyticsCookies[name].getKey(), _objectSpread({}, options));
  }
}