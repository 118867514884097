import * as colors from '@atlaskit/theme/colors';
// Hard coding the active rgba color value rather than using a helper to convert it
// With helper it would be: hex2rgba(colors.B75, 0.6)
var fadedB75 = 'rgba(179, 212, 255, 0.6)';
var values = {
  // Default appearance
  background: {
    default: {
      default: {
        light: "var(--ds-background-neutral, ".concat(colors.N20A, ")"),
        dark: "var(--ds-background-neutral, ".concat(colors.DN70, ")")
      },
      hover: {
        light: "var(--ds-background-neutral-hovered, ".concat(colors.N30A, ")"),
        dark: "var(--ds-background-neutral-hovered, ".concat(colors.DN60, ")")
      },
      active: {
        light: "var(--ds-background-neutral-pressed, ".concat(fadedB75, ")"),
        dark: "var(--ds-background-neutral-pressed, ".concat(colors.B75, ")")
      },
      disabled: {
        light: "var(--ds-background-disabled, ".concat(colors.N20A, ")"),
        dark: "var(--ds-background-disabled, ".concat(colors.DN70, ")")
      },
      selected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.DN0, ")")
      },
      focusSelected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.DN0, ")")
      }
    },
    primary: {
      default: {
        light: "var(--ds-background-brand-bold, ".concat(colors.B400, ")"),
        dark: "var(--ds-background-brand-bold, ".concat(colors.B100, ")")
      },
      hover: {
        light: "var(--ds-background-brand-bold-hovered, ".concat(colors.B300, ")"),
        dark: "var(--ds-background-brand-bold-hovered, ".concat(colors.B75, ")")
      },
      active: {
        light: "var(--ds-background-brand-bold-pressed, ".concat(colors.B500, ")"),
        dark: "var(--ds-background-brand-bold-pressed, ".concat(colors.B200, ")")
      },
      disabled: {
        light: "var(--ds-background-disabled, ".concat(colors.N20A, ")"),
        dark: "var(--ds-background-disabled, ".concat(colors.DN70, ")")
      },
      selected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.DN0, ")")
      },
      focusSelected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.DN0, ")")
      }
    },
    warning: {
      default: {
        light: "var(--ds-background-warning-bold, ".concat(colors.Y300, ")"),
        dark: "var(--ds-background-warning-bold, ".concat(colors.Y300, ")")
      },
      hover: {
        light: "var(--ds-background-warning-bold-hovered, ".concat(colors.Y200, ")"),
        dark: "var(--ds-background-warning-bold-hovered, ".concat(colors.Y200, ")")
      },
      active: {
        light: "var(--ds-background-warning-bold-pressed, ".concat(colors.Y400, ")"),
        dark: "var(--ds-background-warning-bold-pressed, ".concat(colors.Y400, ")")
      },
      disabled: {
        light: "var(--ds-background-disabled, ".concat(colors.N20A, ")"),
        dark: "var(--ds-background-disabled, ".concat(colors.DN70, ")")
      },
      selected: {
        light: "var(--ds-background-selected, ".concat(colors.Y400, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.Y400, ")")
      },
      focusSelected: {
        light: "var(--ds-background-selected, ".concat(colors.Y400, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.Y400, ")")
      }
    },
    danger: {
      default: {
        light: "var(--ds-background-danger-bold, ".concat(colors.R400, ")"),
        dark: "var(--ds-background-danger-bold, ".concat(colors.R400, ")")
      },
      hover: {
        light: "var(--ds-background-danger-bold-hovered, ".concat(colors.R300, ")"),
        dark: "var(--ds-background-danger-bold-hovered, ".concat(colors.R300, ")")
      },
      active: {
        light: "var(--ds-background-danger-bold-pressed, ".concat(colors.R500, ")"),
        dark: "var(--ds-background-danger-bold-pressed, ".concat(colors.R500, ")")
      },
      disabled: {
        light: "var(--ds-background-disabled, ".concat(colors.N20A, ")"),
        dark: "var(--ds-background-disabled, ".concat(colors.DN70, ")")
      },
      selected: {
        light: "var(--ds-background-selected, ".concat(colors.R500, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.R500, ")")
      },
      focusSelected: {
        light: "var(--ds-background-selected, ".concat(colors.R500, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.R500, ")")
      }
    },
    link: {
      default: {
        light: "var(--ds-background-neutral-subtle, none)",
        dark: "var(--ds-background-neutral-subtle, none)"
      },
      selected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.N20, ")")
      },
      focusSelected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.N20, ")")
      }
    },
    subtle: {
      default: {
        light: "var(--ds-background-neutral-subtle, none)",
        dark: "var(--ds-background-neutral-subtle, none)"
      },
      hover: {
        light: "var(--ds-background-neutral-subtle-hovered, ".concat(colors.N30A, ")"),
        dark: "var(--ds-background-neutral-subtle-hovered, ".concat(colors.DN60, ")")
      },
      active: {
        light: "var(--ds-background-neutral-subtle-pressed, ".concat(fadedB75, ")"),
        dark: "var(--ds-background-neutral-subtle-pressed, ".concat(colors.B75, ")")
      },
      disabled: {
        light: "var(--ds-background-neutral-subtle, none)",
        dark: "var(--ds-background-neutral-subtle, none)"
      },
      selected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.DN0, ")")
      },
      focusSelected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.DN0, ")")
      }
    },
    'subtle-link': {
      default: {
        light: "var(--ds-background-neutral-subtle, none)",
        dark: "var(--ds-background-neutral-subtle, none)"
      },
      selected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.N20, ")")
      },
      focusSelected: {
        light: "var(--ds-background-selected, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected, ".concat(colors.N20, ")")
      }
    }
  },
  color: {
    default: {
      default: {
        light: "var(--ds-text, ".concat(colors.N500, ")"),
        dark: "var(--ds-text, ".concat(colors.DN400, ")")
      },
      active: {
        light: "var(--ds-text, ".concat(colors.B400, ")"),
        dark: "var(--ds-text, ".concat(colors.B400, ")")
      },
      disabled: {
        light: "var(--ds-text-disabled, ".concat(colors.N70, ")"),
        dark: "var(--ds-text-disabled, ".concat(colors.DN30, ")")
      },
      selected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.DN400, ")")
      },
      focusSelected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.DN400, ")")
      }
    },
    primary: {
      default: {
        light: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
        dark: "var(--ds-text-inverse, ".concat(colors.DN30, ")")
      },
      disabled: {
        light: "var(--ds-text-disabled, ".concat(colors.N70, ")"),
        dark: "var(--ds-text-disabled, ".concat(colors.DN30, ")")
      },
      selected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.DN400, ")")
      },
      focusSelected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.DN400, ")")
      }
    },
    warning: {
      default: {
        light: "var(--ds-text-warning-inverse, ".concat(colors.N800, ")"),
        dark: "var(--ds-text-warning-inverse, ".concat(colors.N800, ")")
      },
      disabled: {
        light: "var(--ds-text-disabled, ".concat(colors.N70, ")"),
        dark: "var(--ds-text-disabled, ".concat(colors.DN30, ")")
      },
      selected: {
        light: "var(--ds-text-selected, ".concat(colors.N800, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.N800, ")")
      },
      focusSelected: {
        light: "var(--ds-text-selected, ".concat(colors.N800, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.N800, ")")
      }
    },
    danger: {
      default: {
        light: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
        dark: "var(--ds-text-inverse, ".concat(colors.N0, ")")
      },
      disabled: {
        light: "var(--ds-text-disabled, ".concat(colors.N70, ")"),
        dark: "var(--ds-text-disabled, ".concat(colors.DN30, ")")
      },
      selected: {
        light: "var(--ds-text-selected, ".concat(colors.N0, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.N0, ")")
      },
      focusSelected: {
        light: "var(--ds-text-selected, ".concat(colors.N0, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.N0, ")")
      }
    },
    link: {
      default: {
        light: "var(--ds-link, ".concat(colors.B400, ")"),
        dark: "var(--ds-link, ".concat(colors.B100, ")")
      },
      hover: {
        light: "var(--ds-link, ".concat(colors.B300, ")"),
        dark: "var(--ds-link, ".concat(colors.B75, ")")
      },
      active: {
        light: "var(--ds-link-pressed, ".concat(colors.B500, ")"),
        dark: "var(--ds-link-pressed, ".concat(colors.B200, ")")
      },
      disabled: {
        light: "var(--ds-text-disabled, ".concat(colors.N70, ")"),
        dark: "var(--ds-text-disabled, ".concat(colors.DN100, ")")
      },
      selected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.N700, ")")
      },
      focusSelected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.N700, ")")
      }
    },
    subtle: {
      default: {
        light: "var(--ds-text, ".concat(colors.N500, ")"),
        dark: "var(--ds-text, ".concat(colors.DN400, ")")
      },
      active: {
        light: "var(--ds-text, ".concat(colors.B400, ")"),
        dark: "var(--ds-text, ".concat(colors.B400, ")")
      },
      disabled: {
        light: "var(--ds-text-disabled, ".concat(colors.N70, ")"),
        dark: "var(--ds-text-disabled, ".concat(colors.DN100, ")")
      },
      selected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.DN400, ")")
      },
      focusSelected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.DN400, ")")
      }
    },
    'subtle-link': {
      default: {
        light: "var(--ds-text-subtle, ".concat(colors.N200, ")"),
        dark: "var(--ds-text-subtle, ".concat(colors.DN400, ")")
      },
      hover: {
        light: "var(--ds-text-subtle, ".concat(colors.N90, ")"),
        dark: "var(--ds-text-subtle, ".concat(colors.B50, ")")
      },
      active: {
        light: "var(--ds-text, ".concat(colors.N400, ")"),
        dark: "var(--ds-text, ".concat(colors.DN300, ")")
      },
      disabled: {
        light: "var(--ds-text-disabled, ".concat(colors.N70, ")"),
        dark: "var(--ds-text-disabled, ".concat(colors.DN100, ")")
      },
      selected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.DN400, ")")
      },
      focusSelected: {
        light: "var(--ds-text-selected, ".concat(colors.N20, ")"),
        dark: "var(--ds-text-selected, ".concat(colors.DN400, ")")
      }
    }
  }
};
export default values;