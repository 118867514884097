/* eslint-disable no-console */

var printed = {};

/**
 * Will only print a warning message in the console once per session
 *
 * @param message: The message to write as a warning
 */
export default function warnOnce(message) {
  if (printed[message]) {
    return;
  }
  printed[message] = true;
  if (typeof window !== 'undefined') {
    console.warn(message);
  }
}