import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { GuardPolicy, NAMESPACE_AND_TIME_ADDED_INDEX } from './constants';
import { AbandonWriteError } from './errors';
import { commitTransaction, requestToPromise } from './util';

/**
 * A class to enforce a limit on the amount of Analytics Events stored in AWC IndexedDb.
 */
export default class IndexedDbEventCountGuard {
  constructor(eventLimit, namespace, logger, deleteItemHandler) {
    /**
     * This query fetches the oldest events currently stored in AWC IndexedDb object store
     * and deletes them sequentially
     */
    _defineProperty(this, "deleteOldestNEvents", async (objectStore, count) => {
      var _target;
      const productTimeAddedIndex = objectStore.index(NAMESPACE_AND_TIME_ADDED_INDEX);
      const keyRangeValues = IDBKeyRange.bound([this.namespace, 0], [this.namespace, Date.now()]);
      const getAllRequest = productTimeAddedIndex.getAllKeys(keyRangeValues, count);
      const event = await requestToPromise(getAllRequest);
      const result = event === null || event === void 0 || (_target = event.target) === null || _target === void 0 ? void 0 : _target.result;

      // Adding extra safety
      if (result && Array.isArray(result) && result.length > 0) {
        try {
          const deletePromises = result.map(item => this.deleteItemHandler(objectStore, item));
          await Promise.all(deletePromises);
        } catch (error) {
          this.logger.warn('Failed to delete items from indexeddb.', error);
          throw error;
        }
      }
    });
    if (eventLimit > 0) {
      this.eventLimit = eventLimit;
    } else {
      throw Error('Event Limit has to be set higher than 1');
    }
    this.namespace = namespace;
    this.logger = logger;
    this.deleteItemHandler = deleteItemHandler;
  }

  /**
   * This function checks the number of events currently in AWC IndexedDB object store and if necessary,
   * will evict the oldest events in favour of the events we want to add.
   *
   * The indexed is treated as effectively: partitioned by product and sorted by timeAdded.
   *
   * This function can be re-used when the bulk add functionality is added in IndexedDbConnector.
   *
   * @param countOfEventsToAdd - The number of events we are proposing to add.
   */
  async insertItems(objectStore, items, policy = GuardPolicy.ABANDON) {
    const itemsToAdd = [...items];
    let numberOfEvictedItems = 0;
    switch (policy) {
      case GuardPolicy.ABANDON:
        await this.throwIfNotEnoughSpace(objectStore, items.length);
        break;
      case GuardPolicy.EVICT:
        numberOfEvictedItems = await this.evictIfNotEnoughSpace(objectStore, items.length);
        break;
      case GuardPolicy.IGNORE:
        // Removed items from `itemsToAdd` if there is insufficient space
        await this.calculateHowManyEventsWeCanAdd(objectStore, itemsToAdd);
        break;
    }
    const promises = itemsToAdd.map(item => requestToPromise(objectStore.add(item)));
    await Promise.all(promises);
    await commitTransaction(objectStore.transaction, this.logger);
    return {
      items: itemsToAdd,
      numberOfEvictedItems
    };
  }
  async throwIfNotEnoughSpace(objectStore, requiredSpace) {
    const freeSpace = await this.calculateFreeSpace(objectStore);
    if (freeSpace < requiredSpace) {
      throw new AbandonWriteError(`Not enough space in IndexedDb. Needed ${requiredSpace} but only had ${freeSpace}`);
    }
  }
  async evictIfNotEnoughSpace(objectStore, requiredSpace) {
    const freeSpace = await this.calculateFreeSpace(objectStore);
    if (freeSpace < requiredSpace) {
      const extraSpace = requiredSpace - freeSpace;
      await this.deleteOldestNEvents(objectStore, extraSpace);
      return extraSpace;
    }
    return 0;
  }
  async calculateHowManyEventsWeCanAdd(objectStore, items) {
    const freeSpace = await this.calculateFreeSpace(objectStore);
    if (freeSpace < items.length) {
      const numberOfItemsToRemove = items.length - freeSpace;
      const start = items.length - numberOfItemsToRemove;
      items.splice(start, numberOfItemsToRemove);
    }
  }
  async calculateFreeSpace(objectStore) {
    const numberOfEventsInDb = await this.getCountofEventsInObjectStore(objectStore);
    return this.eventLimit - numberOfEventsInDb;
  }

  /**
   * This query returns the count of items held in AWC IndexedDB object store per product.
   */
  async getCountofEventsInObjectStore(objectStore) {
    const productTimeAddedIndex = objectStore.index(NAMESPACE_AND_TIME_ADDED_INDEX);
    const keyRangeValues = IDBKeyRange.bound([this.namespace, 0], [this.namespace, Date.now()]);
    const request = productTimeAddedIndex.count(keyRangeValues);
    const event = await requestToPromise(request);
    return event.target.result;
  }
}