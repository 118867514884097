/* eslint-disable no-return-assign, no-sequences */

// https://www.30secondsofcode.org/js/s/map-keys
const mapKeys = (obj, fn) => Object.keys(obj).reduce((acc, k) => {
  const key = fn(obj[k], k, obj);
  acc[key] = obj[k];
  return acc;
}, {});

// https://www.30secondsofcode.org/js/s/pick
const pick = (obj, arr) => arr.reduce((acc, curr) => (curr in obj && (acc[curr] = obj[curr]), acc), {});

// https://www.30secondsofcode.org/js/s/omit-by
const omitBy = (obj, fn) => Object.keys(obj).filter(k => !fn(obj[k], k)).reduce((acc, key) => (acc[key] = obj[key], acc), {});

// https://www.30secondsofcode.org/js/s/pick-by
const pickBy = (obj, fn) => Object.keys(obj).filter(k => fn(obj[k], k)).reduce((acc, key) => (acc[key] = obj[key], acc), {});

// https://www.30secondsofcode.org/js/s/omit
const omit = (obj, arr) => Object.keys(obj).filter(k => arr.indexOf(k) < 0).reduce((acc, key) => (acc[key] = obj[key], acc), {});

// https://www.30secondsofcode.org/js/s/equals
const equals = (a, b) => {
  if (a === b) {
    return true;
  }
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }
  if (!a || !b || typeof a !== 'object' && typeof b !== 'object') {
    return a === b;
  }
  if (a.prototype !== b.prototype) {
    return false;
  }
  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) {
    return false;
  }
  return keys.every(k => equals(a[k], b[k]));
};

// https://www.30secondsofcode.org/js/s/partition
const partition = (arr, fn) => arr.reduce(
// eslint-disable-next-line no-shadow
(acc, val, i, arr) => {
  acc[fn(val, i, arr) ? 0 : 1].push(val);
  return acc;
}, [[], []]);
export { mapKeys, pick, pickBy, omitBy, omit, equals, partition };