import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { BMEventsType } from '../types';
import { custom, interaction } from './core';
var concurrentMetrics = {};
var getKey = function getKey(config, type) {
  return "".concat(type, "--").concat(config.key);
};
var validateConfig = function validateConfig(config) {
  if (config.include) {
    throw new Error('"include" is not supported in concurrent actions');
  }
  if (config.until) {
    throw new Error('"until" is not supported in concurrent actions');
  }
};
var ensurePath = function ensurePath(config, type) {
  var key = getKey(config, type);
  if (!(key in concurrentMetrics)) {
    concurrentMetrics[key] = {};
  }
};
var getMetricFromCache = function getMetricFromCache(concurrentId, config, type) {
  var concurrentKey = getKey(config, type);
  if (concurrentKey in concurrentMetrics && concurrentMetrics[concurrentKey][String(concurrentId)]) {
    return concurrentMetrics[concurrentKey][String(concurrentId)];
  }
  if (!(concurrentKey in concurrentMetrics)) {
    concurrentMetrics[concurrentKey] = {};
  }
};
var makeConcurrentMetric = function makeConcurrentMetric(concurrentId, config, type) {
  validateConfig(config);
  ensurePath(config, type);
  var metricFromCache = getMetricFromCache(concurrentId, config, type);
  if (metricFromCache) {
    return metricFromCache;
  }
  var metricFactory = type === BMEventsType.INLINE_RESULT ? interaction : custom;
  var metric = metricFactory(config);
  concurrentMetrics[getKey(config, type)][String(concurrentId)] = metric;
  return metric;
};
export var concurrent = {
  pageSegmentLoad: function pageSegmentLoad(config) {
    return function (concurrentId) {
      return makeConcurrentMetric(concurrentId, _objectSpread({}, config), BMEventsType.PAGE_SEGMENT_LOAD);
    };
  },
  interaction: function interaction(config) {
    return function (concurrentId) {
      return makeConcurrentMetric(concurrentId, _objectSpread({}, config), BMEventsType.INLINE_RESULT);
    };
  },
  custom: function custom(config) {
    return function (concurrentId) {
      return makeConcurrentMetric(concurrentId, _objectSpread({}, config), BMEventsType.CUSTOM);
    };
  }
};