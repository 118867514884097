"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserCacheKey = exports.getSortedObject = exports.djb2HashForObject = exports.getHashValue = exports.SimpleHash = void 0;
function SimpleHash(value) {
    var hash = 0;
    for (var i = 0; i < value.length; i++) {
        var character = value.charCodeAt(i);
        hash = (hash << 5) - hash + character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return String(hash >>> 0);
}
exports.SimpleHash = SimpleHash;
function getHashValue(value, hash_used) {
    if (hash_used === 'none') {
        return value;
    }
    return SimpleHash(value);
}
exports.getHashValue = getHashValue;
function djb2HashForObject(object) {
    return SimpleHash(JSON.stringify(getSortedObject(object)));
}
exports.djb2HashForObject = djb2HashForObject;
function getSortedObject(object) {
    if (object == null) {
        return null;
    }
    var keys = Object.keys(object).sort();
    var sortedObject = {};
    keys.forEach(function (key) {
        var value = object[key];
        if (value instanceof Object) {
            value = getSortedObject(value);
        }
        sortedObject[key] = value;
    });
    return sortedObject;
}
exports.getSortedObject = getSortedObject;
function getUserCacheKey(user) {
    var _a;
    var key = "userID:".concat(String((_a = user === null || user === void 0 ? void 0 : user.userID) !== null && _a !== void 0 ? _a : ''));
    var customIDs = user === null || user === void 0 ? void 0 : user.customIDs;
    if (customIDs != null) {
        for (var _i = 0, _b = Object.entries(customIDs); _i < _b.length; _i++) {
            var _c = _b[_i], type = _c[0], value = _c[1];
            key += ";".concat(type, ":").concat(value);
        }
    }
    return SimpleHash(key);
}
exports.getUserCacheKey = getUserCacheKey;
