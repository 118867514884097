"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.difference = exports.now = void 0;
function now() {
    if (typeof performance === 'undefined' || !performance) {
        return Date.now();
    }
    return performance.now() | 0;
}
exports.now = now;
function difference(time) {
    return now() - time;
}
exports.difference = difference;
