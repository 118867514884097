import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var bundleEvalTimingsInternal = function bundleEvalTimingsInternal(_config, data, globalConfig) {
  var _globalConfig$enableB;
  var performance = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : window.performance;
  var started = {};
  var timings = {};
  var enableBundleEvalTimings = (_globalConfig$enableB = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.enableBundleEvalTimings) !== null && _globalConfig$enableB !== void 0 ? _globalConfig$enableB : true;
  performance.getEntriesByType('mark').forEach(function (mark) {
    if (!globalConfig.bundleEvalTimings) {
      return;
    }
    var result = globalConfig.bundleEvalTimings.mapPerformanceMark(mark.name);
    if (!result) {
      return;
    }
    var type = result.type,
      name = result.name;
    if (type === 'start' && data.start !== null && mark.startTime >= data.start) {
      started[name] = mark;
      return;
    }
    if (type === 'end' && started[name]) {
      timings[name] = {
        startTime: Math.round(started[name].startTime - data.start),
        duration: Math.round(mark.startTime - started[name].startTime)
      };
      delete started[name];
    }
  });
  if (globalConfig.bundleEvalTimings && globalConfig.bundleEvalTimings.additionalTimings && data.start !== null && enableBundleEvalTimings) {
    var result = globalConfig.bundleEvalTimings.additionalTimings(data.start);
    if (result) {
      Object.entries(result).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        timings[key] = value;
      });
    }
  }
  if (Object.keys(timings).length === 0) {
    return null;
  }
  return {
    'timings:bundleEval': timings
  };
};
export var bundleEvalTimings = function bundleEvalTimings() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(bundleEvalTimingsInternal.apply(void 0, args));
    });
  });
};