import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { parseUrl, stringify } from 'query-string';
import uuidv4 from 'uuid/v4';
import { originTracingType } from './analyticsWebTypes';
import { mapKeys, omit, pick } from './objectUtils';
import SafeSessionStorage from './storage/SafeSessionStorage';
import urlParamExtractor from './urlUtils';
const STORAGE_KEY = 'taskSessions';
const INIT_KEY = 'taskSessionsInit';
const AWC_PARAM_PREFIX = 'awc.';
const TASK_SESSION_RELOAD_TIME = 3000;
export default class TaskSessionStore {
  constructor() {
    _defineProperty(this, "_taskSessionsNotRecentlyInitialised", currentTimestamp => this._safeSessionStorage.getItem(STORAGE_KEY) === null || this._safeSessionStorage.getItem(INIT_KEY) === null || parseInt(this._safeSessionStorage.getItem(INIT_KEY) || '0', 10) < currentTimestamp - TASK_SESSION_RELOAD_TIME);
    _defineProperty(this, "_removeTaskSessionPrefix", (_, taskSessionName) => taskSessionName.split(AWC_PARAM_PREFIX)[1]);
    _defineProperty(this, "_isTaskSessionQueryParam", (_, taskSessionName) => taskSessionName.startsWith(AWC_PARAM_PREFIX));
    _defineProperty(this, "getAllTaskSessions", () => {
      try {
        return JSON.parse(this._safeSessionStorage.getItem(STORAGE_KEY) || '');
      } catch (e) {
        return {};
      }
    });
    _defineProperty(this, "_writeToSessionStorage", taskSessions => {
      try {
        this._safeSessionStorage.setItem(STORAGE_KEY, JSON.stringify(taskSessions));
        // eslint-disable-next-line no-empty
      } catch (e) {}
    });
    _defineProperty(this, "_appendTaskSessions", newTaskSessions => {
      const currentState = this.getAllTaskSessions();
      const newState = currentState === null ? newTaskSessions : Object.assign(currentState, newTaskSessions);
      this._writeToSessionStorage(newState);
    });
    _defineProperty(this, "_safelyRemoveKey", (currentState, taskSessionName) => {
      if (currentState === null) {
        return {};
      }
      delete currentState[taskSessionName];
      return currentState;
    });
    _defineProperty(this, "createTaskSession", taskSessionName => {
      const taskSessionId = uuidv4();
      const newTaskSessions = {};
      newTaskSessions[taskSessionName] = taskSessionId;
      this._appendTaskSessions(newTaskSessions);
      return taskSessionId;
    });
    _defineProperty(this, "createTaskSessionWithProvidedId", (taskSessionName, taskSessionId) => {
      if (typeof taskSessionId !== 'string') {
        throw new TypeError('invalid taskSessionId, must be string');
      }
      const newTaskSessions = {};
      newTaskSessions[taskSessionName] = taskSessionId;
      this._appendTaskSessions(newTaskSessions);
    });
    _defineProperty(this, "completeTaskSession", taskSessionName => {
      const currentState = this.getAllTaskSessions();
      const updatedState = this._safelyRemoveKey(currentState, taskSessionName);
      this._writeToSessionStorage(updatedState);
    });
    _defineProperty(this, "formatTaskSessionQueryString", ({
      uri,
      includedTaskSessions
    }) => {
      const {
        url,
        query
      } = parseUrl(uri);
      const currentState = this.getAllTaskSessions();

      // Use specified task sessions, otherwise all tasksessions excluding originTracing sessions
      const filteredTaskSessions = includedTaskSessions ? pick(currentState, includedTaskSessions) : omit(currentState, Object.values(originTracingType));
      if (Object.keys(filteredTaskSessions).length === 0) {
        return uri;
      }
      const queryWithTaskSessions = _objectSpread(_objectSpread({}, mapKeys(filteredTaskSessions, (_, taskSessionName) => AWC_PARAM_PREFIX + taskSessionName)), query);
      return `${url}?${stringify(queryWithTaskSessions)}`;
    });
    _defineProperty(this, "stripQueryParameters", () => {
      const extractedTaskSessions = mapKeys(urlParamExtractor(this._isTaskSessionQueryParam), this._removeTaskSessionPrefix);
      this._appendTaskSessions(extractedTaskSessions);
    });
    const _currentTimestamp = Date.now();
    this._safeSessionStorage = new SafeSessionStorage();
    // Wrapping all sessionStorage calls in try catch to stop any error propogation, taskSessions should gracefully fail.
    try {
      if (this._taskSessionsNotRecentlyInitialised(_currentTimestamp)) {
        this._writeToSessionStorage({});
        this._safeSessionStorage.setItem(INIT_KEY, _currentTimestamp.toString());
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}