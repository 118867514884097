import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class InMemoryStore {
  constructor() {
    _defineProperty(this, "_data", {});
    _defineProperty(this, "length", 0);
    _defineProperty(this, "setItem", (key, value) => {
      this._data[key] = value;
      this.length = Object.keys(this._data).length;
      return value;
    });
    _defineProperty(this, "getItem", key => {
      if (key in this._data) {
        return this._data[key];
      }
      return null;
    });
    _defineProperty(this, "removeItem", key => {
      if (key in this._data) {
        delete this._data[key];
      }
      this.length = Object.keys(this._data).length;
      return null;
    });
    _defineProperty(this, "clear", () => {
      this._data = {};
      this.length = 0;
    });
    _defineProperty(this, "key", index => Object.keys(this._data)[index]);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InMemoryStore();