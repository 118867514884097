import { XIDItemType } from './enums';
export const XID_TIMEOUT = 5000;
export const createTimeoutXid = () => [{
  type: XIDItemType.XC,
  state: 'TIMEOUT'
}, {
  type: XIDItemType.UID,
  state: 'TIMEOUT'
}];
export const createUnknownXid = () => [{
  type: XIDItemType.XC,
  state: 'UNKNOWN'
}, {
  type: XIDItemType.UID,
  state: 'UNKNOWN'
}];