import Bowser from 'bowser-ultralight';
export var telemetryBrowser = function telemetryBrowser() {
  if (!Bowser.getParser) {
    return null;
  }
  var browser = Bowser.getParser(window.navigator.userAgent);
  return {
    'telemetry:browser:name': browser.getBrowserName(),
    'telemetry:browser:version': browser.getBrowserVersion()
  };
};