/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This file contains a dynamic import for each theme this package exports.
 * Themes are loaded asynchronously at runtime to minimise the amount of CSS we send to the client.
 * This allows users to compose their themes and only use the tokens that are requested.
 * When a new theme is created, the import should automatically be added to the map
 *
 * @codegen <<SignedSource::8352f41e09dfe9d45ead708661744456>>
 * @codegenCommand yarn build tokens
 */

var themeImportsMap = {
  'light': function light() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-light" */
    './themes/atlassian-light');
  },
  'light-future': function lightFuture() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-light-future" */
    './themes/atlassian-light-future');
  },
  'light-increased-contrast': function lightIncreasedContrast() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-light-increased-contrast" */
    './themes/atlassian-light-increased-contrast');
  },
  'dark': function dark() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-dark" */
    './themes/atlassian-dark');
  },
  'dark-future': function darkFuture() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-dark-future" */
    './themes/atlassian-dark-future');
  },
  'dark-increased-contrast': function darkIncreasedContrast() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-dark-increased-contrast" */
    './themes/atlassian-dark-increased-contrast');
  },
  'legacy-light': function legacyLight() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-legacy-light" */
    './themes/atlassian-legacy-light');
  },
  'legacy-dark': function legacyDark() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-legacy-dark" */
    './themes/atlassian-legacy-dark');
  },
  'spacing': function spacing() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-spacing" */
    './themes/atlassian-spacing');
  },
  'typography-adg3': function typographyAdg3() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-typography-adg3" */
    './themes/atlassian-typography-adg3');
  },
  'shape': function shape() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-shape" */
    './themes/atlassian-shape');
  },
  'typography-modernized': function typographyModernized() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-typography-modernized" */
    './themes/atlassian-typography-modernized');
  },
  'typography-refreshed': function typographyRefreshed() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-typography-refreshed" */
    './themes/atlassian-typography-refreshed');
  },
  'light-brand-refresh': function lightBrandRefresh() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-light-brand-refresh" */
    './themes/atlassian-light-brand-refresh');
  },
  'dark-brand-refresh': function darkBrandRefresh() {
    return import( /* webpackChunkName: "@atlaskit-internal_atlassian-dark-brand-refresh" */
    './themes/atlassian-dark-brand-refresh');
  }
};
export default themeImportsMap;