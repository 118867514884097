"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExceptionEndpoint = void 0;
var Errors_1 = require("./Errors");
exports.ExceptionEndpoint = 'https://statsigapi.net/v1/sdk_exception';
var ErrorBoundary = /** @class */ (function () {
    function ErrorBoundary(sdkKey, options) {
        this._seen = new Set();
        this._sdkKey = sdkKey;
        this._options = options;
    }
    ErrorBoundary.prototype._setStatsigMetadata = function (statsigMetadata) {
        this._statsigMetadata = statsigMetadata;
    };
    ErrorBoundary.prototype._swallow = function (tag, task) {
        this._capture(tag, task, function () {
            return undefined;
        });
    };
    ErrorBoundary.prototype._capture = function (tag, task, recover, getExtraData) {
        var _this = this;
        try {
            var result = task();
            if (result instanceof Promise) {
                return result.catch(function (e) {
                    return _this._onCaught(tag, e, recover, getExtraData);
                });
            }
            return result;
        }
        catch (error) {
            return this._onCaught(tag, error, recover, getExtraData);
        }
    };
    ErrorBoundary.prototype._logError = function (tag, error, getExtraData) {
        var _this = this;
        if (this._options.disableAllLogging)
            return;
        try {
            var impl = function () { return __awaiter(_this, void 0, void 0, function () {
                var extra, _a, unwrapped, isError, name, info, metadata, body;
                var _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!(typeof getExtraData === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, getExtraData()];
                        case 1:
                            _a = _c.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            _a = null;
                            _c.label = 3;
                        case 3:
                            extra = _a;
                            unwrapped = (error !== null && error !== void 0 ? error : Error('[Statsig] Error was empty'));
                            isError = unwrapped instanceof Error;
                            name = isError ? unwrapped.name : 'No Name';
                            if (this._seen.has(name))
                                return [2 /*return*/];
                            this._seen.add(name);
                            info = isError
                                ? unwrapped.stack
                                : this._getDescription(unwrapped);
                            metadata = (_b = this._statsigMetadata) !== null && _b !== void 0 ? _b : {};
                            body = JSON.stringify({
                                tag: tag,
                                exception: name,
                                info: info,
                                statsigMetadata: metadata,
                                extra: extra !== null && extra !== void 0 ? extra : {},
                            });
                            return [2 /*return*/, fetch(exports.ExceptionEndpoint, {
                                    method: 'POST',
                                    headers: {
                                        'STATSIG-API-KEY': this._sdkKey,
                                        'STATSIG-SDK-TYPE': String(metadata['sdkType']),
                                        'STATSIG-SDK-VERSION': String(metadata['sdkVersion']),
                                        'Content-Type': 'application/json',
                                        'Content-Length': "".concat(body.length),
                                    },
                                    body: body,
                                })];
                    }
                });
            }); };
            impl()
                .then(function () {
                /* noop */
            })
                .catch(function () {
                /* noop */
            });
        }
        catch (_error) {
            /* noop */
        }
    };
    ErrorBoundary.prototype._onCaught = function (tag, error, recover, getExtraData) {
        if (error instanceof Errors_1.StatsigUninitializedError ||
            error instanceof Errors_1.StatsigInvalidArgumentError) {
            throw error; // Don't catch these
        }
        console.error('[Statsig] An unexpected exception occurred.', error);
        this._logError(tag, error, getExtraData);
        return recover();
    };
    ErrorBoundary.prototype._getDescription = function (obj) {
        try {
            return JSON.stringify(obj);
        }
        catch (_a) {
            return '[Statsig] Failed to get string for error.';
        }
    };
    return ErrorBoundary;
}());
exports.default = ErrorBoundary;
