import { isInteractionConfig } from '../../metric/types';
import { BMInteractionMetrics, isInteractionMetricsSlo } from '../../types';
export var interactionMetrics = function interactionMetrics(config, data, globalConfig) {
  var _config$histogram;
  if (!isInteractionConfig(config)) {
    return null;
  }
  var result = {
    'metric:result': Math.round(data.stop - data.start)
  };
  if ((_config$histogram = config.histogram) !== null && _config$histogram !== void 0 && _config$histogram.result) {
    result['metric:result:histogramBuckets'] = config.histogram.result;
  } else if (globalConfig.histogram) {
    var histogram = globalConfig.histogram;
    if (histogram.result !== undefined) {
      result['metric:result:histogramBuckets'] = histogram[BMInteractionMetrics.result];
    }
  }
  if (config.slo && isInteractionMetricsSlo(config.slo) && config.slo.result) {
    result['metric:result:slo'] = result['metric:result'] <= config.slo.result.threshold;
    result['metric:result:slo:threshold'] = config.slo.result.threshold;
  }
  if (data.marks['feedback']) {
    var _config$histogram2;
    result['metric:response'] = Math.round((data.marks['feedback'] || data.stop) - data.start);
    if ((_config$histogram2 = config.histogram) !== null && _config$histogram2 !== void 0 && _config$histogram2.response) {
      result['metric:response:histogramBuckets'] = config.histogram[BMInteractionMetrics.response];
    } else if (globalConfig.histogram) {
      var _histogram = globalConfig.histogram;
      if (_histogram.result !== undefined) {
        result['metric:response:histogramBuckets'] = _histogram[BMInteractionMetrics.response];
      }
    }
    if (config.slo && isInteractionMetricsSlo(config.slo) && config.slo.response) {
      result['metric:response:slo'] = result['metric:response'] <= config.slo.response.threshold;
      result['metric:response:slo:threshold'] = config.slo.response.threshold;
    }
  }
  return result;
};