import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SSRGetDoneMark } from './helper/ssr-get-done-mark';
import { logger } from './logger';
import { pageLoadMetaMetric } from './metric/page-load-meta-metric';
import { visibilityChangeObserver } from './observer/visibility-change-observer';
import { webVitalsObserver } from './observer/web-vitals-observer';
import { Route } from './route';
import { submitter } from './submitter/submitter';
import { BMEventsType } from './types';
var makeBrowserMetrics = function makeBrowserMetrics() {
  var bm = {
    init: function init(config) {
      var _config$events$BMEven, _config$ssr;
      visibilityChangeObserver.start();
      if ((_config$events$BMEven = config.events[BMEventsType.WEB_VITALS]) !== null && _config$events$BMEven !== void 0 && _config$events$BMEven.enabled) {
        webVitalsObserver.start();
      }
      if (config.debug) {
        logger.enable();
        submitter.setDebug(true);
        pageLoadMetaMetric.setDebug(true);
      }
      submitter.configure(config);
      if ((_config$ssr = config.ssr) !== null && _config$ssr !== void 0 && _config$ssr.getDoneMark) {
        SSRGetDoneMark.setGetDoneMark(config.ssr.getDoneMark);
      }
    },
    setRoute: function setRoute(newRoute) {
      Route.setRoute(newRoute);
    },
    startPageLoad: function startPageLoad(params) {
      pageLoadMetaMetric.startPageLoad(_objectSpread({
        isInitial: false,
        cancelStarted: true
      }, params));
    },
    getPageLoadMetric: function getPageLoadMetric() {
      return pageLoadMetaMetric;
    },
    experimental__addPageLoadTimingFromPerformanceMarks: function experimental__addPageLoadTimingFromPerformanceMarks(name, startMark, stopMark) {
      var initialOnly = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      if (initialOnly && !pageLoadMetaMetric.isInitialLoad()) {
        return;
      }
      var start = performance.getEntriesByName(startMark);
      var stop = performance.getEntriesByName(stopMark);
      if (start.length > 0 && stop.length > 0) {
        if (pageLoadMetaMetric.isDebugMode()) {
          try {
            performance.measure(name, startMark, stopMark);
          } catch (e) {}
        }
        var pageLoadData = pageLoadMetaMetric.getData();
        if (pageLoadData.start !== null) {
          var _startMark = start[start.length - 1];
          var timing = {
            startTime: Math.round(_startMark.startTime - pageLoadData.start),
            duration: Math.round(stop[stop.length - 1].startTime - _startMark.startTime)
          };
          pageLoadMetaMetric.experimental__addExplicitTiming(name, timing);
        }
      }
    }
  };
  return bm;
};
export var browserMetrics = makeBrowserMetrics();