import { NESTED_METRIC_SEPARATOR } from './constants';
var retrieveTimings = function retrieveTimings(config, data) {
  var relativeTo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var prefix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  var configTimings = config.timings || [];
  var timings = configTimings.reduce(function (entries, timing) {
    if (timing.startMark && !data.marks[timing.startMark]) {
      return entries;
    }
    if (timing.endMark && !data.marks[timing.endMark]) {
      return entries;
    }
    if (data.start === null || data.stop === null) {
      return null;
    }
    var startTime = Math.round((data.marks[timing.startMark] !== undefined ? data.marks[timing.startMark] : data.start) - data.start + relativeTo);
    var duration = Math.round((data.marks[timing.endMark] !== undefined ? data.marks[timing.endMark] : data.stop) - data.start - startTime + relativeTo);
    entries["".concat(prefix).concat(timing.key)] = {
      startTime: startTime,
      duration: duration
    };
    return entries;
  }, {});
  return timings;
};
export var customTimings = function customTimings(config, data) {
  if (!config.timings && data.submetrics.length === 0) {
    return null;
  }
  var timings = retrieveTimings(config, data);
  data.submetrics.forEach(function (submetric) {
    if (submetric.start !== null && submetric.stop !== null) {
      var submetricPrefix = "include:".concat(submetric.config.key);
      timings[submetricPrefix] = {
        startTime: Math.round(submetric.start - data.start),
        duration: Math.round(submetric.stop - submetric.start)
      };
      Object.assign(timings, retrieveTimings(submetric.config, submetric, submetric.start - data.start, submetricPrefix + NESTED_METRIC_SEPARATOR));
    }
  });
  if (Object.keys(timings).length === 0) {
    return null;
  }
  return timings;
};