import { createTimeoutXid, createUnknownXid, XID_TIMEOUT } from './constants';
export const XIDPromise = (xidConsent, xidPromiseFn) => {
  if (xidConsent && xidPromiseFn && typeof xidPromiseFn === 'function') {
    const timeoutPromise = () => {
      return new Promise(resolve => {
        setTimeout(() => resolve(() => createTimeoutXid()), XID_TIMEOUT);
      });
    };
    const xidPromise = () => {
      return xidPromiseFn().catch(() => () => createUnknownXid());
    };
    return Promise.race([xidPromise(), timeoutPromise()]);
  }
  return undefined;
};