import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { GuardPolicy, StoreType } from './constants';
import { AbandonWriteError } from './errors';
export default class MemoryDbEventCountGuard {
  constructor(eventLimit, memoryItemHandlers) {
    /**
     * Unlike IndexedDb, we cannot take advantage of any index. So we are,
     * treating the memory store as a sorted set when adding items.
     */
    _defineProperty(this, "insertItemsToMemoryStore", (itemsToAdd, policy) => {
      switch (policy) {
        case GuardPolicy.ABANDON:
          return this.handleAbandonIfLimitWillExceedPolicy(itemsToAdd);
        case GuardPolicy.EVICT:
          return Promise.resolve(this.handleEvictOldestIfLimitExceededPolicy(itemsToAdd));
        case GuardPolicy.IGNORE:
          return this.handleAddAsManyAsPossible(itemsToAdd);
      }
    });
    if (eventLimit > 0) {
      this.eventLimit = eventLimit;
    } else {
      throw Error('Event Limit has to be set higher than 1');
    }
    this.memoryItemHandlers = memoryItemHandlers;
  }
  async handleAbandonIfLimitWillExceedPolicy(itemsToAdd) {
    const itemCount = await this.memoryItemHandlers.getItemCount();
    if (itemCount + itemsToAdd.length > this.eventLimit) {
      throw new AbandonWriteError(StoreType.MEMORY);
    }
    this.memoryItemHandlers.addItems(itemsToAdd);
    return {
      items: itemsToAdd,
      numberOfEvictedItems: 0
    };
  }
  handleEvictOldestIfLimitExceededPolicy(itemsToAdd) {
    this.memoryItemHandlers.addItems(itemsToAdd);
    const numberOfEvictedItems = this.memoryItemHandlers.evictEventsIfNeeded(this.eventLimit);
    return {
      items: itemsToAdd,
      numberOfEvictedItems
    };
  }
  async handleAddAsManyAsPossible(itemsToAdd) {
    const freeSpace = this.eventLimit - (await this.memoryItemHandlers.getItemCount());
    const partialEventsToAdd = freeSpace > itemsToAdd.length ? itemsToAdd : itemsToAdd.slice(0, freeSpace);
    this.memoryItemHandlers.addItems(partialEventsToAdd);
    return {
      items: partialEventsToAdd,
      numberOfEvictedItems: 0
    };
  }
}