import _typeof from "@babel/runtime/helpers/typeof";
import { NESTED_METRIC_SEPARATOR } from './constants';
var filterEntry = function filterEntry(entry) {
  return !(!entry || _typeof(entry) !== 'object' || entry.startTime < 0 || entry.duration < 0);
};
var mapEntry = function mapEntry(entry) {
  return {
    startTime: Math.round(entry.startTime),
    duration: Math.round(entry.duration)
  };
};
var SSR_PREFIX = 'ssr';
var mapKey = function mapKey(key) {
  if (key === 'total') {
    return SSR_PREFIX;
  }
  return "".concat(SSR_PREFIX).concat(NESTED_METRIC_SEPARATOR).concat(key);
};
export var ssrTimings = function ssrTimings(_config, _data, globalConfig) {
  if (!globalConfig.ssr || !globalConfig.ssr.getTimings) {
    return null;
  }
  var timings = globalConfig.ssr.getTimings();
  if (!timings) {
    return null;
  }
  var ssrTimings = Object.entries(timings).reduce(function (acc, entry) {
    if (filterEntry(entry[1])) {
      acc[mapKey(entry[0])] = mapEntry(entry[1]);
    }
    return acc;
  }, {});
  if (!ssrTimings || Object.keys(ssrTimings).length === 0) {
    return null;
  }
  return ssrTimings;
};