import _defineProperty from "@babel/runtime/helpers/defineProperty";
import uuidv4 from 'uuid/v4';
import SafeSessionStorage from './storage/SafeSessionStorage';
const STORAGE_KEY = 'tab.id';
export default class TabTracking {
  constructor() {
    _defineProperty(this, "_generateNewTabId", () => {
      const newTabId = uuidv4();
      this._safeSessionStorage.setItem(STORAGE_KEY, newTabId);
      return newTabId;
    });
    this._safeSessionStorage = new SafeSessionStorage();
  }
  getCurrentTabId() {
    let tabId = this._safeSessionStorage.getItem(STORAGE_KEY);
    if (!tabId) {
      tabId = this._generateNewTabId();
    }
    return tabId;
  }
}