export var sizes = {
  small: '16px',
  medium: '24px',
  large: '32px',
  xlarge: '48px'
};
export var sizeMap = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge'
};
export var dimensions = {
  small: {
    width: sizes.small,
    height: sizes.small
  },
  medium: {
    width: sizes.medium,
    height: sizes.medium
  },
  large: {
    width: sizes.large,
    height: sizes.large
  },
  xlarge: {
    width: sizes.xlarge,
    height: sizes.xlarge
  }
};