import _defineProperty from "@babel/runtime/helpers/defineProperty";
/*
 * Code heavily influenced by Segment's Store https://github.com/segmentio/localstorage-retry/blob/master/lib/store.js
 * and Engine https://github.com/segmentio/localstorage-retry/blob/master/lib/engine.js
 * But is not used because it's not public API and is too restrictive in the way it sets keys and does JSON parsing and stringify
 */
import { envType } from '../analyticsWebTypes';
import InMemoryStorage from './InMemoryStorage';
export const AWC_STORAGE_PREFIX = 'awc';
export const GLOBAL_IN_MEMORY_NAME = 'awcInMemoryStorageFallback';
export function isSupported(store) {
  const TEST_VALUE = 'test_value';
  if (!store) {
    return false;
  }
  try {
    const key = 'awc.storage.support';
    store.setItem(key, TEST_VALUE);
    const value = store.getItem(key);
    store.removeItem(key);

    // handle localStorage silently failing
    return value === TEST_VALUE;
  } catch (e) {
    // Can throw if localStorage is disabled
    return false;
  }
}
export function isQuotaExceeded(e) {
  let quotaExceeded = false;
  if (e.code) {
    switch (e.code) {
      case 22:
        quotaExceeded = true;
        break;
      case 1014:
        // Firefox
        if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
          quotaExceeded = true;
        }
        break;
      default:
        break;
    }
  } else if (e.number === -2147024882) {
    // Internet Explorer 8
    quotaExceeded = true;
  }
  return quotaExceeded;
}
export default class SafeStorage {
  constructor(storageToUse, options = {}) {
    _defineProperty(this, "getStore", () => this._store);
    _defineProperty(this, "getItem", key => this._store.getItem(this.createKey(key)));
    _defineProperty(this, "removeItem", key => this._store.removeItem(this.createKey(key)));
    _defineProperty(this, "setItem", (key, value) => {
      try {
        this._store.setItem(this.createKey(key), value);
      } catch (err) {
        if (isQuotaExceeded(err)) {
          this.swapToInMemory();
          this._store.setItem(this.createKey(key), value);
        }
      }
    });
    _defineProperty(this, "swapToInMemory", () => {
      if (this._store === InMemoryStorage || typeof window === 'undefined') {
        return;
      }
      const keys = Object.keys(this._store || {});
      let inMemoryStorage;
      if (isSupported(window[GLOBAL_IN_MEMORY_NAME])) {
        inMemoryStorage = window[GLOBAL_IN_MEMORY_NAME];
      } else {
        inMemoryStorage = InMemoryStorage;
        if (!window[GLOBAL_IN_MEMORY_NAME]) {
          // If not already attached to window then attach our instance of InMemoryStorage
          // We need to do this because if there are multiple instances of Analytics Client in multiple scopes,
          // there is no way to guarantee the throttling behaviour of the UI viewed event across those instances without a global singleton
          window[GLOBAL_IN_MEMORY_NAME] = InMemoryStorage;
        }
      }
      keys.forEach(key => {
        if (key.indexOf(this.getPrefix()) === 0) {
          const value = this._store.getItem(key);
          if (value) {
            inMemoryStorage.setItem(key, value);
          }
        }
      });
      this._store = inMemoryStorage;
    });
    _defineProperty(this, "clear", () => this._store.clear());
    _defineProperty(this, "key", index => this._store.key(index));
    _defineProperty(this, "getPrefix", () => this._prefix);
    _defineProperty(this, "createKey", key => {
      if (this._prefix) {
        return `${this.getPrefix()}.${key}`;
      }
      return key;
    });
    this.options = options;
    const prefixes = [];
    if (options.useStoragePrefix === undefined || options.useStoragePrefix) {
      prefixes.push(AWC_STORAGE_PREFIX);
    }
    if (options.envPrefix && options.envPrefix !== envType.PROD) {
      prefixes.push(options.envPrefix);
    }
    this._prefix = prefixes.join('-');
    if (isSupported(storageToUse)) {
      this._store = storageToUse;
    } else {
      this.swapToInMemory();
    }
  }
}