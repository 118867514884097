"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
function makeLogEvent(eventName, user, statsigMetadata, value, metadata, secondaryExposures) {
    if (value === void 0) { value = null; }
    if (metadata === void 0) { metadata = null; }
    var logUser = user;
    if (logUser === null || logUser === void 0 ? void 0 : logUser.privateAttributes) {
        logUser = __assign({}, user);
        delete logUser.privateAttributes;
    }
    return {
        time: Date.now(),
        eventName: eventName,
        statsigMetadata: statsigMetadata,
        user: logUser,
        value: value,
        metadata: metadata,
        secondaryExposures: secondaryExposures,
    };
}
exports.default = makeLogEvent;
