import { isPageLoadConfig } from '../../metric/types';
import { BMPageLoadMetrics, isDetailedPageLoadSlo } from '../../types';
var getThreshold = function getThreshold(isInitial, metric) {
  return isDetailedPageLoadSlo(metric) ? isInitial ? metric.initial.threshold : metric.transition.threshold : metric.threshold;
};
var getHistogram = function getHistogram(isInitial, metric, histogram) {
  return isInitial ? histogram.initial[metric] : histogram.transition[metric];
};
export var pageLoadMetrics = function pageLoadMetrics(config, data, globalConfig) {
  if (!isPageLoadConfig(config)) {
    throw new Error('Browser Metrics: cannot gather page load data for non-page-load config');
  }
  var fmp = data.marks[BMPageLoadMetrics.fmp] || data.stop;
  var result = {
    'metric:fmp': Math.round(fmp - data.start),
    'metric:tti': Math.round(data.stop - data.start)
  };
  if (config.histogram) {
    result['metric:fmp:histogramBuckets'] = getHistogram(data.isInitial, BMPageLoadMetrics.fmp, config.histogram);
    result['metric:tti:histogramBuckets'] = getHistogram(data.isInitial, BMPageLoadMetrics.tti, config.histogram);
  } else if (globalConfig.histogram) {
    var histogram = globalConfig.histogram;
    result['metric:fmp:histogramBuckets'] = getHistogram(data.isInitial, BMPageLoadMetrics.fmp, histogram);
    result['metric:tti:histogramBuckets'] = getHistogram(data.isInitial, BMPageLoadMetrics.tti, histogram);
  }
  if (config.slo && config.slo.fmp) {
    var threshold = getThreshold(data.isInitial, config.slo.fmp);
    result['metric:fmp:slo'] = result['metric:fmp'] <= threshold;
    result['metric:fmp:slo:threshold'] = threshold;
  }
  if (config.slo && config.slo.tti) {
    var _threshold = getThreshold(data.isInitial, config.slo.tti);
    result['metric:tti:slo'] = result['metric:tti'] <= _threshold;
    result['metric:tti:slo:threshold'] = _threshold;
  }
  return result;
};