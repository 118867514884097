import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { buildActionName } from '../eventBuilder';
import { equals, partition } from '../objectUtils';
import EventCompressor from './eventCompressor';
// We want to throttle the flush rate so that we don't have a huge upfront performance hit from starting the flush,
// and so that the underlying Segment client has some time to process some events in its queue before more are added.
const FLUSH_BATCH_SIZE = 7; // aligns with the default batch size of Segment's BatchableQueue
const FLUSH_BATCH_BACKOFF_PERIOD = 100;
export default class EventDelayQueue {
  constructor(processFn, compressionRules) {
    _defineProperty(this, "push", (identifier, builtEvent, context, userInfo) => {
      this.eventArgs.push({
        identifier,
        builtEvent,
        context,
        userInfo
      });
    });
    _defineProperty(this, "size", () => this.eventArgs.length);
    _defineProperty(this, "startFlush", () => {
      try {
        this.eventArgs = this.compressEventArgs(this.eventArgs);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Failed to perform compression on the delayed analytics events. ' + `Error: ${e.message}. Sending ${this.eventArgs.length} uncompressed events instead`);
      }
      this.flushNextBatch();
    });
    _defineProperty(this, "cancelFlush", () => {
      if (this.flushBatchTimeout) {
        clearTimeout(this.flushBatchTimeout);
        this.flushBatchTimeout = null;
      }
    });
    _defineProperty(this, "flushNextBatch", () => {
      const batch = this.eventArgs.splice(0, FLUSH_BATCH_SIZE);
      batch.forEach(item => this.processFn(item.identifier, item.builtEvent, item.context, item.userInfo));
      if (this.eventArgs.length > 0) {
        this.flushBatchTimeout = setTimeout(() => this.flushNextBatch(), FLUSH_BATCH_BACKOFF_PERIOD);
      } else {
        this.flushBatchTimeout = null;
      }
    });
    _defineProperty(this, "compressEventArgs", eventArgs => {
      const [compressibleEventArgs, incompressibleEventArgs] = partition(eventArgs, args => this.compressor.canCompress(args.builtEvent));

      // Events can only be compressed together if they share the same context and user info, since these are top-level
      // fields that need to exist on the fired event and can only be set to a single value.
      // We can achieve this by grouping our events by context prior to passing them to the compressor.
      const contextGroups = compressibleEventArgs.reduce((groups, args) => {
        const matchingGroup = groups.find(group => equals(group.userInfo, args.userInfo) && equals(group.context, args.context));
        if (matchingGroup) {
          matchingGroup.eventArgs.push(args);
        } else {
          groups.push({
            userInfo: args.userInfo,
            context: args.context,
            eventArgs: [args]
          });
        }
        return groups;
      }, []);

      // Run the compressor on each group
      const allCompressedEventArgs = contextGroups.reduce((acc, group) => {
        try {
          const events = group.eventArgs.map(args => args.builtEvent);
          const compressedEvents = this.compressor.compress(events);
          const compressedEventArgs = compressedEvents.map(compressedEvent => ({
            identifier: buildActionName(compressedEvent),
            builtEvent: compressedEvent,
            userInfo: group.userInfo,
            context: group.context
          }));
          return acc.concat(compressedEventArgs);
        } catch (e) {
          // If anything goes wrong while compressing this group, then just fall back on the
          // uncompressed events instead. The event compressor already handles errors with invalid
          // generator functions or results, but this is an extra layer of defense to prevent data
          // loss in the event of an unexpected error.
          // eslint-disable-next-line no-console
          console.warn('Failed to compress some analytics events. ' + `Error: ${e.message}. Sending ${group.eventArgs.length} uncompressed events instead`);
          return group.eventArgs;
        }
      }, []);
      incompressibleEventArgs.forEach(args => allCompressedEventArgs.push(args));
      return allCompressedEventArgs;
    });
    this.processFn = processFn;
    this.flushBatchTimeout = null;
    this.eventArgs = [];
    this.compressor = new EventCompressor(compressionRules);
  }
}