import SafeLocalStorage from '../storage/SafeLocalStorage';
export default class LocalStorageAlt {
  constructor() {
    this.safeStorage = new SafeLocalStorage({
      useStoragePrefix: false
    });
  }
  set(name, value) {
    this.safeStorage.setItem(name, value);
    return value;
  }
  get(name) {
    const value = this.safeStorage.getItem(name);
    return value || undefined;
  }
  remove(name) {
    this.safeStorage.removeItem(name);
  }
}