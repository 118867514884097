import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { bind } from 'bind-event-listener';
var isMatchMediaAvailable = function isMatchMediaAvailable() {
  return typeof window !== 'undefined' && 'matchMedia' in window;
};

/**
 * Use for any programatic motions needed at runtime.
 * Will return `true` if the current user prefers reduced motion.
 * This is generally set through OS preferences/settings.
 */
export var isReducedMotion = function isReducedMotion() {
  if (!isMatchMediaAvailable()) {
    return false;
  }
  var _window$matchMedia = window.matchMedia('(prefers-reduced-motion: reduce)'),
    matches = _window$matchMedia.matches;
  return matches;
};

/**
 * A React hook version of {@link isReducedMotion}.
 * Useful for React components that need to re-render if the user's motion
 * preference changes at runtime.
 */
export var useIsReducedMotion = function useIsReducedMotion() {
  var _useState = useState(isReducedMotion),
    _useState2 = _slicedToArray(_useState, 2),
    prefersReducedMotion = _useState2[0],
    setPrefersReducedMotion = _useState2[1];
  useEffect(function () {
    if (!isMatchMediaAvailable()) {
      return;
    }
    var mediaQueryList = window.matchMedia('(prefers-reduced-motion: reduce)');
    var onChange = function onChange(event) {
      return setPrefersReducedMotion(event.matches);
    };
    return bind(mediaQueryList, {
      type: 'change',
      listener: onChange
    });
  }, []);
  return prefersReducedMotion;
};

/**
 * Use for any CSS based motion (animation or transition).
 * Always put at the end of your declaration for correct use of the cascade.
 * Reduced motion preference is generally set through OS preferences/settings.
 */
export var reduceMotionAsPerUserPreference = {
  '@media (prefers-reduced-motion: reduce)': {
    animation: 'none',
    transition: 'none'
  }
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-4709 Internal documentation for deprecation (no external access)}
 * Use the sibling export `reduceMotionAsPerUserPreference` instead.
 */
export var prefersReducedMotion = function prefersReducedMotion() {
  return reduceMotionAsPerUserPreference;
};