import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export var featureFlags = function featureFlags(config, _data, globalConfig) {
  var _config$featureFlags;
  var localFeatureFlags = ((_config$featureFlags = config.featureFlags) === null || _config$featureFlags === void 0 ? void 0 : _config$featureFlags.reduce(function (acc, ffName) {
    acc[ffName] = globalConfig.ffClient.getValue(ffName);
    return acc;
  }, {})) || null;
  if ((!globalConfig.featureFlags || Object.keys(globalConfig.featureFlags).length === 0) && (!localFeatureFlags || Object.keys(localFeatureFlags).length === 0)) {
    return null;
  }
  return {
    featureFlags: _objectSpread(_objectSpread({}, localFeatureFlags), globalConfig.featureFlags)
  };
};