import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import tokens from '../artifacts/token-names';
import { CUSTOM_THEME_ATTRIBUTE, THEME_DATA_ATTRIBUTE } from '../constants';
import { hash } from './hash';
export function findMissingCustomStyleElements(UNSAFE_themeOptions, mode) {
  var optionString = JSON.stringify(UNSAFE_themeOptions);
  var uniqueId = hash(optionString);
  var attrOfMissingCustomStyles = [];
  (mode === 'auto' ? ['light', 'dark'] : [mode]).forEach(function (themeId) {
    var element = document.head.querySelector("style[".concat(CUSTOM_THEME_ATTRIBUTE, "=\"").concat(uniqueId, "\"][").concat(THEME_DATA_ATTRIBUTE, "=\"").concat(themeId, "\"]"));
    if (element) {
      // Append the existing custom styles to take precedence over others
      document.head.appendChild(element);
    } else {
      attrOfMissingCustomStyles.push(themeId);
    }
  });
  return attrOfMissingCustomStyles;
}
export function limitSizeOfCustomStyleElements(sizeThreshold) {
  var styleTags = _toConsumableArray(Array.from(document.head.querySelectorAll("style[".concat(CUSTOM_THEME_ATTRIBUTE, "][").concat(THEME_DATA_ATTRIBUTE, "]"))));
  if (styleTags.length < sizeThreshold) {
    return;
  }
  styleTags.slice(0, styleTags.length - (sizeThreshold - 1)).forEach(function (element) {
    return element.remove();
  });
}
export function reduceTokenMap(tokenMap, themeRamp) {
  return Object.entries(tokenMap).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    var cssVar = tokens[key];
    return cssVar ? "".concat(acc, "\n  ").concat(cssVar, ": ").concat(typeof value === 'string' ? value : themeRamp[value], ";") : acc;
  }, '');
}