import { BMEventsType } from '../types';
export var isPageLoadMetricData = function isPageLoadMetricData(data) {
  return data.type === BMEventsType.PAGE_LOAD;
};
export var isPageSegmentLoadMetricData = function isPageSegmentLoadMetricData(data) {
  return data.type === BMEventsType.PAGE_SEGMENT_LOAD;
};
export var isPageLoadConfig = function isPageLoadConfig(data) {
  return data.type === BMEventsType.PAGE_LOAD;
};
export var isPageSegmentLoadConfig = function isPageSegmentLoadConfig(data) {
  return data.type === BMEventsType.PAGE_SEGMENT_LOAD;
};
export var isInteractionConfig = function isInteractionConfig(data) {
  return data.type === BMEventsType.INLINE_RESULT;
};
export var isCustomConfig = function isCustomConfig(data) {
  return data.type === BMEventsType.CUSTOM;
};