import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';
var useIsSubsequentRender = function useIsSubsequentRender(mountStrategy) {
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isSubsequentRender = _useState2[0],
    setIsSubsequentRender = _useState2[1];
  var _useState3 = useState(function () {
      return mountStrategy === 'layoutEffect' ? useIsomorphicLayoutEffect : useEffect;
    }),
    _useState4 = _slicedToArray(_useState3, 1),
    useMountEffect = _useState4[0];
  useMountEffect(function () {
    setIsSubsequentRender(true);
  }, []);
  return isSubsequentRender;
};
export default useIsSubsequentRender;