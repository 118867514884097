import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { originTracingType } from './analyticsWebTypes';
import urlParamExtractor from './urlUtils';
export default class OriginTracing {
  constructor() {
    _defineProperty(this, "handleOriginParameters", (originParamHandlerMapping, historyReplaceFn) => {
      // Collect all the parameter labels to be handled
      const originParamLabelList = Object.keys(originParamHandlerMapping);
      if (!(originParamLabelList.length > 0)) {
        throw new Error('Empty parameter mapping provided');
      }

      // Check if the parameter labels are valid. If any invalid labels are found throw an exception.
      const invalidParams = originParamLabelList.filter(x => Object.values(originTracingType).indexOf(x) < 0);
      if (invalidParams.length > 0) {
        throw new Error(`Invalid Origin Tracing Parameter(s) supplied: ${invalidParams.join()}!`);
      }
      const isCaptureParam = (_, key) => originParamLabelList.indexOf(key) >= 0;
      // Capture and remove params from URL
      const capturedURLParams = urlParamExtractor(isCaptureParam, historyReplaceFn);
      // Apply provided handler function to extract origin trace ID from each URL parameter
      const applyHandlerFunction = key => {
        const x = originParamHandlerMapping[key](capturedURLParams[key]);
        return {
          [key]: x
        };
      };
      const extractedOriginID = Object.keys(capturedURLParams).map(applyHandlerFunction).reduce((accumulator, currentValue) => Object.assign(accumulator, currentValue), {});
      return extractedOriginID;
    });
    this._originStore = {};
  }
}