import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import memoize from 'memoize-one';
export const sendEvents = async options => {
  var _createOrGetAbortCont;
  const signal = (_createOrGetAbortCont = createOrGetAbortController(options)) === null || _createOrGetAbortCont === void 0 ? void 0 : _createOrGetAbortCont.signal;
  const corsOptions = getCorsForUrl(options.url);
  const response = await fetch(options.url, _objectSpread({
    method: 'POST',
    // Content-type has to be text/plain in order to prevent unnecessary `OPTIONS` requests.
    headers: {
      'Content-Type': 'text/plain'
    },
    body: JSON.stringify(options.batch),
    signal
  }, corsOptions));
  if (response.status === 429 || response.status >= 500 && response.status < 600) {
    throw new Error(`HTTP Error ${response.status} (${response.statusText})`);
  }
  return response;
};
const createOrGetAbortController = options => {
  if (options.abortController) {
    return options.abortController;
  } else if (options.timeout) {
    const abortController = new AbortController();

    // After a fetch has completed, calling abort is ignored.
    setTimeout(() => {
      abortController.abort();
    }, options.timeout);
    return abortController;
  }
};

// To ensure we always have cookies, we should check if the origins match.
// If they dont, we need to set mode to `cors` and credentials to `include`.
const getCorsForUrl = memoize(url => {
  const origin = new URL(url).origin;
  if (origin === location.origin) {
    return {};
  } else {
    return {
      mode: 'cors',
      credentials: 'include'
    };
  }
});