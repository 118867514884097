import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ResilienceMechanism } from './types';
export class MetricsCollector {
  constructor() {
    // Number of events discarded due to exceeding retry limits.
    _defineProperty(this, "itemsDiscardedByRetry", 0);
    // Number of events created by the product in current tab.
    _defineProperty(this, "eventCount", 0);
    // Number of events discarded due to reaching queue max size.
    _defineProperty(this, "itemsDiscardedByOverflow", 0);
    // Will be used in the future when we get more resilience mechanisms
    _defineProperty(this, "resilienceMechanism", ResilienceMechanism.INDEXEDDB);
    /* --- LocalStorage specific metrics --- */
    // Emitted by purgeOrphanedQueues to tell us how many queues we have had to clean up due to
    // https://github.com/segmentio/localstorage-retry/pull/17
    _defineProperty(this, "localstorageNumberOfQueuesPurged", 0);
    // Emitted by reclaimEvents to tell us how many queues were seen in the process of reclaim.
    // We will only emit metrics for queues that are not currently being maintained (i.e. `ack` is not being updated).
    // Queue was found, all events removed and put into resilienceDb.
    _defineProperty(this, "localstorageNumberOfFullReclaims", 0);
    // Queue was found but not all events were able to be put into resilienceDb.
    _defineProperty(this, "localstorageNumberOfPartialReclaims", 0);
    // Reclaim failed for a queue and reclaim was abandonded.
    _defineProperty(this, "localstorageNumberOfFailedReclaims", 0);
  }
  addToItemsDiscardedByRetryCounter(numberOfDeletedEvents = 1) {
    this.itemsDiscardedByRetry += numberOfDeletedEvents;
  }
  addToEventCount() {
    this.eventCount++;
  }
  addToItemsDiscardedByOverflowCounter(numberOfDeletedEvents = 1) {
    this.itemsDiscardedByOverflow += numberOfDeletedEvents;
  }
  setResilienceMechanism(mechanism) {
    this.resilienceMechanism = mechanism;
  }

  /* <Localstorage specific> */

  addToReclaimMetrics(reclaimMetrics) {
    this.localstorageNumberOfFullReclaims += reclaimMetrics.fullReclaims;
    this.localstorageNumberOfPartialReclaims += reclaimMetrics.partialReclaims;
    this.localstorageNumberOfFailedReclaims += reclaimMetrics.failedReclaims;
  }
  addToPurgedQueuesMetrics(numberOfQueuesPurged) {
    this.localstorageNumberOfQueuesPurged += numberOfQueuesPurged;
  }

  /* </Localstorage specific> */

  subtractFromMetrics(sentMetrics) {
    this.itemsDiscardedByRetry = this.subtractFromCount(this.itemsDiscardedByRetry, sentMetrics.itemsDiscardedByRetry);
    this.eventCount = this.subtractFromCount(this.eventCount, sentMetrics.eventCount);
    this.itemsDiscardedByOverflow = this.subtractFromCount(this.itemsDiscardedByOverflow, sentMetrics.itemsDiscardedByOverflow);

    // Localstorage specific
    this.localstorageNumberOfFullReclaims = this.subtractFromCount(this.localstorageNumberOfFullReclaims, sentMetrics.fullReclaims);
    this.localstorageNumberOfPartialReclaims = this.subtractFromCount(this.localstorageNumberOfPartialReclaims, sentMetrics.partialReclaims);
    this.localstorageNumberOfFailedReclaims = this.subtractFromCount(this.localstorageNumberOfFailedReclaims, sentMetrics.failedReclaims);
    this.localstorageNumberOfQueuesPurged = this.subtractFromCount(this.localstorageNumberOfQueuesPurged, sentMetrics.localstorageQueuesPurged);
  }
  subtractFromCount(count, subtract) {
    return Math.max(count - (subtract || 0), 0);
  }
  getMetricsPayload() {
    return {
      itemsDiscardedByRetry: this.itemsDiscardedByRetry,
      eventCount: this.eventCount,
      itemsDiscardedByOverflow: this.itemsDiscardedByOverflow,
      resilienceMechanism: this.resilienceMechanism,
      // Localstorage specific
      localstorageQueuesPurged: this.localstorageNumberOfQueuesPurged,
      fullReclaims: this.localstorageNumberOfFullReclaims,
      partialReclaims: this.localstorageNumberOfPartialReclaims,
      failedReclaims: this.localstorageNumberOfFailedReclaims
    };
  }
}
let cachedMetricsClient = null;
export default (() => {
  if (!cachedMetricsClient) {
    cachedMetricsClient = new MetricsCollector();
  }
  return cachedMetricsClient;
});