import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import uuid from 'uuid/v4';
import getBatchableQueue from '../resilienceQueue';
import getMetricsCollector from '../resilienceQueue/Metrics';
import { attachXidToMultipleEvents } from '../xid';
import { DEFAULT_REQUEST_TIMEOUT } from './defaults';
import { HTTP_STATUS_CODE_MAP } from './HttpStatusCode';
import { sendEvents } from './sendEvents';
import { SegmentEventTypes } from './types';
import User from './user';
import { buildContext, prepareEventProperties } from './util';
class AnalyticsAPIResponse {
  constructor(success, validationErrors, message, code, validationReports) {
    this.success = success;
    this.validationErrors = validationErrors;
    this.message = message;
    this.code = code;
    this.validationReports = validationReports;
  }
  isSuccess() {
    return this.success;
  }
  getValidationErrors() {
    return this.validationErrors;
  }
  getMessage() {
    return this.message;
  }
  getCode() {
    return this.code;
  }
  getValidationReports() {
    return this.validationReports;
  }
}
class Message {
  constructor(error) {
    this.error = error;
  }
}
class EventValidationReport {
  constructor(messageId, results) {
    this.messageId = messageId;
    this.results = results;
  }
  getMessageId() {
    return this.messageId;
  }
  getResults() {
    return this.results;
  }
}
export class AnalyticsResponse {
  constructor(success, statusCode, message, eventResponseList) {
    this.success = success;
    this.statusCode = statusCode;
    this.message = message;
    this.eventResponseList = eventResponseList;
  }
  isSuccess() {
    return this.success;
  }
  getStatusCode() {
    return this.statusCode;
  }
  getMessage() {
    return this.message;
  }
  getEventResponseList() {
    return this.eventResponseList;
  }
}
class EventResponse {
  constructor(messageId, results, segmentEvent) {
    this.messageId = messageId;
    this.results = results;
    this.segmentEvent = segmentEvent;
  }
  getMessageId() {
    return this.messageId;
  }
  getResults() {
    return this.results;
  }
  getSegmentEvent() {
    return this.segmentEvent;
  }
}
class EventValidationResult {
  constructor(type, severity, propertyPath, message) {
    this.type = type;
    this.severity = severity;
    this.propertyPath = propertyPath;
    this.message = message;
  }
  getType() {
    return this.type;
  }
  getSeverity() {
    return this.severity;
  }
  getPropertyPath() {
    return this.propertyPath;
  }
  getMessage() {
    return this.message;
  }
}
export default class EventProcessor {
  constructor(options) {
    var _this$options;
    // Using anonymous function so it can have the BatchFlushCallback type associated with it
    // And to allow it to not need bind when passing through to ResilieceQueue.
    _defineProperty(this, "sendEvents", async (items, callback) => {
      const httpRetryCount = this.resilienceQueue.getGlobalRetryCount();
      const metricsPayload = this.metrics.getMetricsPayload();
      const metadata = _objectSpread(_objectSpread({}, metricsPayload), {}, {
        httpRetryCount
      });
      for (let key in metadata) {
        // @ts-ignore Some keys maybe a string, but these will never equal 0
        if (metadata[key] === 0) {
          // @ts-ignore Save space in requests by removing metrics with no impact
          delete metadata[key];
        }
      }
      const eventsWithXID = await this.attachXIDs(items);

      // Calculating sentAt after the XID generation as this may take some time.
      const sentAt = new Date().toISOString();
      const unpackagedEvents = eventsWithXID.map(item => {
        item.msg.sentAt = sentAt;
        return item.msg;
      });
      const batchBody = {
        batch: unpackagedEvents,
        sentAt,
        metadata
      };
      const localResponseCallback = this.responseCallback;
      try {
        const response = await sendEvents({
          url: this.gasv3BatchUrl,
          batch: batchBody,
          timeout: this.options.requestTimeout
        });
        this.metrics.subtractFromMetrics(metricsPayload);
        callback(null, response);
        if (localResponseCallback !== null && response !== null) {
          const analyticsAPIResponse = await response.clone().json();
          const analyticsResponse = BatchInterceptor.toAnalyticsResponse(items, null, analyticsAPIResponse);
          localResponseCallback(analyticsResponse);
        }
      } catch (error) {
        callback(error, null);
        if (localResponseCallback !== null && error !== null) {
          const analyticsResponse = BatchInterceptor.toAnalyticsResponse(items, error, null);
          localResponseCallback(analyticsResponse);
        }
      }
    });
    this.options = _objectSpread(_objectSpread({}, options), {}, {
      requestTimeout: options.requestTimeout || DEFAULT_REQUEST_TIMEOUT,
      retryQueueOptions: options.retryQueueOptions || {},
      logger: options.logger || console,
      disableCookiePersistence: options.disableCookiePersistence || false
    });
    this.user = new User((_this$options = this.options) === null || _this$options === void 0 ? void 0 : _this$options.disableCookiePersistence);
    this.xidPromiseCallback = options.xidPromiseGetter();
    this.gasv3BatchUrl = `${options.apiHostProtocol}://${options.apiHost}/batch`;
    this.metrics = getMetricsCollector();
    this.responseCallback = options.responseCallback;
    this.resilienceQueue = getBatchableQueue(options.retryQueuePrefix, options.product, this.options.retryQueueOptions, this.options.logger);
    this.resilienceQueue.start(this.sendEvents);
  }
  getUser() {
    return this.user;
  }
  async track(eventName, builtEvent, context, callback) {
    const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.TRACK, builtEvent);
    const eventWithoutMessageId = _objectSpread(_objectSpread({}, baseEvent), {}, {
      type: SegmentEventTypes.TRACK,
      properties: prepareEventProperties(builtEvent),
      event: eventName
    });
    const event = _objectSpread(_objectSpread({}, eventWithoutMessageId), {}, {
      messageId: this.createMessageId()
    });
    const packagedEvent = this.packageEvent(event);
    await this.resilienceQueue.addItem(packagedEvent);
    if (callback) {
      callback();
    }
  }
  async page(eventName, builtEvent, context, callback) {
    const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.PAGE, builtEvent);
    const eventWithoutMessageId = _objectSpread(_objectSpread({}, baseEvent), {}, {
      type: SegmentEventTypes.PAGE,
      properties: prepareEventProperties(builtEvent),
      name: eventName
    });
    const event = _objectSpread(_objectSpread({}, eventWithoutMessageId), {}, {
      messageId: this.createMessageId()
    });
    const packagedEvent = this.packageEvent(event);
    await this.resilienceQueue.addItem(packagedEvent);
    if (callback) {
      callback();
    }
  }

  // Segment uses the identifier to update user id which we have already done in the analyticsWebClient.ts
  async identify(_identifier, builtEvent, context, callback) {
    const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.IDENTIFY, builtEvent);
    const eventWithoutMessageId = _objectSpread(_objectSpread({}, baseEvent), {}, {
      type: SegmentEventTypes.IDENTIFY,
      traits: prepareEventProperties(builtEvent)
    });
    const event = _objectSpread(_objectSpread({}, eventWithoutMessageId), {}, {
      messageId: this.createMessageId()
    });
    const packagedEvent = this.packageEvent(event);
    await this.resilienceQueue.addItem(packagedEvent);
    if (callback) {
      callback();
    }
  }
  buildBaseEvent(context, type, overrides) {
    const clonedContext = prepareEventProperties(context);
    const segmentContext = buildContext(clonedContext);
    return {
      context: segmentContext,
      timestamp: new Date().toISOString(),
      type,
      userId: this.user.getUserId(),
      anonymousId: overrides.anonymousId || this.user.getAnonymousId()
    };
  }
  createMessageId() {
    return `ajs-${uuid()}`;
  }
  packageEvent(event) {
    const {
      apiHost,
      apiHostProtocol
    } = this.options;
    return {
      headers: {
        'Content-Type': 'text/plain'
      },
      msg: event,
      url: `${apiHostProtocol}://${apiHost}/${event.type.charAt(0)}`
    };
  }
  async attachXIDs(items) {
    if (this.xidPromiseCallback) {
      return attachXidToMultipleEvents(items, this.xidPromiseCallback);
    }
    return Promise.resolve(items);
  }
  setResponseCallback(responseCallback) {
    this.responseCallback = responseCallback;
  }
}
class BatchInterceptor {
  static toAnalyticsResponse(items, error, analyticsAPIResponse) {
    const eventResponseList = [];
    if (analyticsAPIResponse !== null) {
      const [statusCode, success] = [analyticsAPIResponse.code, analyticsAPIResponse.success];
      if (statusCode >= 200 && statusCode <= 299) {
        var _analyticsAPIResponse;
        (_analyticsAPIResponse = analyticsAPIResponse.validationReports) === null || _analyticsAPIResponse === void 0 || _analyticsAPIResponse.forEach(report => {
          const matchedEvent = items.find(item => item.msg.messageId === report.messageId);
          if (matchedEvent) {
            eventResponseList.push(new EventResponse(matchedEvent.msg.messageId, report.results, matchedEvent.msg));
          }
        });
        return new AnalyticsResponse(success, statusCode, HTTP_STATUS_CODE_MAP.get(statusCode), eventResponseList);
      } else if (statusCode >= 400 && statusCode <= 499) {
        var _analyticsAPIResponse2;
        // not exluding "HTTP 429 Too Many Requests error" as it is thrown as an error and handled below
        const errorMessage = ((_analyticsAPIResponse2 = analyticsAPIResponse.message) === null || _analyticsAPIResponse2 === void 0 ? void 0 : _analyticsAPIResponse2.error) !== null ? analyticsAPIResponse.message.error : HTTP_STATUS_CODE_MAP.has(statusCode) ? HTTP_STATUS_CODE_MAP.get(statusCode) : HTTP_STATUS_CODE_MAP.get(-1);
        items.forEach(item => {
          eventResponseList.push(new EventResponse(item.msg.messageId, [], item.msg));
        });
        return new AnalyticsResponse(success, statusCode, errorMessage, eventResponseList);
      }
    } else if (error !== null && error.message !== null) {
      const match = error.message.match(BatchInterceptor.http_error_regex);
      if (match) {
        items.forEach(item => {
          eventResponseList.push(new EventResponse(item.msg.messageId, [], item.msg));
        });
        return new AnalyticsResponse(false, Number(match[1]), match[2], eventResponseList);
      }
    }
    items.forEach(item => {
      eventResponseList.push(new EventResponse(item.msg.messageId, [], item.msg));
    });
    return new AnalyticsResponse(false, -1, HTTP_STATUS_CODE_MAP.get(-1), eventResponseList);
  }
}
_defineProperty(BatchInterceptor, "http_error_regex", /HTTP Error\s([0-9]{3})\s\((.+)\)/);