import Cookie from 'js-cookie';
import memoize from 'memoize-one';
import { AllAnalyticsCookies } from './AllAnalyticsCookies';
export const canUseCookie = (disableCookiePersistence = false) => {
  if (disableCookiePersistence) {
    return false;
  }
  return !(location.protocol === 'file:' || location.protocol === 'chrome-extension:');
};

// Only exported for testing
export const COOKIE_NAME = AllAnalyticsCookies.AWC_TLD_TEST.getKey();
export const COOKIE_VALUE = 'tld_test';

// This is an expensive function so we should only run this once per page load
export const getTld = memoize(() => {
  if (typeof location === 'undefined') {
    return '';
  }
  const {
    hostname
  } = location;
  const splitHostname = hostname.split('.');
  for (let i = 1; i <= splitHostname.length; i++) {
    const domainParts = splitHostname.slice(i * -1);
    const domain = `.${domainParts.join('.')}`;
    Cookie.set(COOKIE_NAME, COOKIE_VALUE, {
      domain
    });
    if (Cookie.get(COOKIE_NAME) === COOKIE_VALUE) {
      Cookie.remove(COOKIE_NAME, {
        domain
      });
      return domain;
    }
  }
  return hostname;
});