import { isCustomConfig } from '../../metric/types';
export var customEventMetrics = function customEventMetrics(config, data, globalConfig) {
  var _config$slo, _config$histogram;
  if (!isCustomConfig(config)) {
    return null;
  }
  var payload = {
    'metric:duration': Math.round(data.stop - data.start)
  };
  if (((_config$slo = config.slo) === null || _config$slo === void 0 ? void 0 : _config$slo.threshold) !== undefined) {
    var _config$slo2, _config$slo3;
    payload['metric:duration:slo'] = payload['metric:duration'] <= ((_config$slo2 = config.slo) === null || _config$slo2 === void 0 ? void 0 : _config$slo2.threshold);
    payload['metric:duration:slo:threshold'] = (_config$slo3 = config.slo) === null || _config$slo3 === void 0 ? void 0 : _config$slo3.threshold;
  }
  if ((_config$histogram = config.histogram) !== null && _config$histogram !== void 0 && _config$histogram.duration) {
    payload['metric:duration:histogramBuckets'] = config.histogram.duration;
  } else if (globalConfig.histogram) {
    payload['metric:duration:histogramBuckets'] = globalConfig.histogram.duration;
  }
  return payload;
};