import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { purgeOrphanedQueues, reclaimEvents } from '../localstorage-retry';
import ResilienceDb, { CallbackProcessingErrorName, GuardPolicy } from '../resiliencedb';
import getMetricsCollector from './Metrics';
import NetworkStatus from './NetworkStatus';
import Scheduler, { DoneState } from './scheduler';
import { NetworkStatusEnum } from './types';
const ONE_MINUTE = 60000;
const LONG_POLLING_OPTIONS = {
  backoffFactor: 0,
  backoffJitterPercentage: 0,
  flushWaitMs: ONE_MINUTE,
  minRetryDelay: ONE_MINUTE,
  maxRetryDelay: ONE_MINUTE
};
export const DEFAULT_POLLING_OPTIONS = {
  backoffFactor: 2,
  backoffJitterPercentage: 0.2,
  batchFlushSize: 7,
  flushBeforeUnload: false,
  flushWaitMs: 500,
  maxAttempts: 10,
  // TODO figure out this number. For indexeddb we are sharing the queue with all tabs
  maxItems: 1000,
  maxRetryDelay: 30000,
  minRetryDelay: 1000
};
export default class PullBatchableQueue {
  constructor(queuePrefix, product, options, logger) {
    this.queuePrefix = queuePrefix;
    // Avoiding using options in ResilienceDB at this stage as
    // the options are optimised for the PushBatchableQueue
    this.resilience = new ResilienceDb(product, {
      logger,
      maxAttempts: options === null || options === void 0 ? void 0 : options.maxAttempts,
      maxEventLimit: options === null || options === void 0 ? void 0 : options.maxItems
    });
    this.options = this.buildOptions(options);
    this.scheduler = new Scheduler(_objectSpread(_objectSpread({}, this.options), {}, {
      waitInterval: options !== null && options !== void 0 && options.flushBeforeUnload ? 0 : this.options.flushWaitMs
    }), this.scheduleCallback.bind(this));
    this.longPollingScheduler = new Scheduler(_objectSpread(_objectSpread({}, LONG_POLLING_OPTIONS), {}, {
      waitInterval: LONG_POLLING_OPTIONS.flushWaitMs
    }), this.scheduleLongCallBack.bind(this));
    this.metricsCollector = getMetricsCollector();
    this.networkStatus = new NetworkStatus(status => {
      if (status === NetworkStatusEnum.OFFLINE) {
        this.scheduler.stop();
      } else {
        this.checkEventCountAndReschedule();
      }
    });
  }
  start(flushCallback) {
    this.batchFlushCallback = flushCallback;
    this.longPollingScheduler.schedule();
  }
  stop() {
    this.scheduler.stop();
    this.longPollingScheduler.stop();
  }
  async addItem(item) {
    // Since Jira and Confluence use `.toJSON` on objects that should be strings
    // And expect us to use `JSON.stringify` on these objects,
    // And indexeddb strips these functions from the objects when adding them to the DB,
    // We must run this silly stringify and parse before passing the event to the resilience meechanism.
    // Planning on removing this in v4
    const jsonStringifiedAndParsedItem = JSON.parse(JSON.stringify(item));
    const {
      numberOfEvictedItems
    } = await this.resilience.addItem(jsonStringifiedAndParsedItem, {}, GuardPolicy.EVICT);
    this.scheduler.schedule();
    this.metricsCollector.addToEventCount();
    if (numberOfEvictedItems > 0) {
      this.metricsCollector.addToItemsDiscardedByOverflowCounter(numberOfEvictedItems);
    }
  }
  getGlobalRetryCount() {
    return this.scheduler.getFailureCount();
  }
  async scheduleCallback(done) {
    // Even though we stopped the scheduler, it can be restarted with a call to schedule.
    // This could be triggered by a new event or the longPollingScheduler
    if (this.networkStatus.getNetworkStatus() === NetworkStatusEnum.OFFLINE) {
      done(DoneState.NOOP);
      return;
    }
    try {
      const result = await this.resilience.processItems(this.flush.bind(this), this.options.batchFlushSize);
      done(result);
    } catch (error) {
      if (error.name === CallbackProcessingErrorName) {
        done(DoneState.ERROR);
        return;
      }
      // TODO we can probably log here but throwing is useless as it will just be shallowed by the Scheduler
      // In theory something else should catch all other errors from indexeddb and this should never happen.
    } finally {
      await this.checkEventCountAndReschedule();
    }
  }
  async checkEventCountAndReschedule() {
    const eventWaitingCount = await this.resilience.getItemCount();
    if (eventWaitingCount > 0) {
      this.scheduler.schedule({
        immediate: eventWaitingCount >= this.options.batchFlushSize
      });
    }
  }
  async scheduleLongCallBack(done) {
    // Eventually we will want to send data about how many queues were purged
    const numberOfQueuesPurged = purgeOrphanedQueues(this.queuePrefix);
    this.metricsCollector.addToPurgedQueuesMetrics(numberOfQueuesPurged);
    // Eventually we will want to send data about how attempted reclaims and actual reclaims were made
    const reclaimMetrics = await reclaimEvents(this.queuePrefix, async localStorageItems => {
      const itemsToAdd = localStorageItems.map(localStorageItem => ({
        item: localStorageItem.item,
        retryAttempts: localStorageItem.attemptNumber,
        id: localStorageItem.id
      }));
      const {
        items: addedEvents
      } = await this.resilience.bulkAddItem(itemsToAdd, GuardPolicy.IGNORE);
      if (addedEvents.length === itemsToAdd.length) {
        return {
          status: 'successful'
        };
      } else {
        return {
          status: 'partial',
          acceptedItemIds: addedEvents.map(event => event.id)
        };
      }
    });
    this.metricsCollector.addToReclaimMetrics(reclaimMetrics);

    // We dont want to flush events from here as there maybe issues with analytics-service
    // Scheduler has all the context and can schedule the next batch accordingly
    await this.checkEventCountAndReschedule();
    done(DoneState.SUCCESS);
    this.longPollingScheduler.schedule();
  }
  flush(wrappedItems, otherMetadata) {
    this.metricsCollector.setResilienceMechanism(this.resilience.storeType());
    const {
      batchFlushCallback
    } = this;
    if (!batchFlushCallback) {
      throw new Error('batchFlushCallback has not been set. Call PullBatchableQueue#start first.');
    }
    return new Promise((resolve, reject) => {
      if (wrappedItems.length <= 0) {
        resolve(DoneState.NOOP);
        return;
      }
      const items = wrappedItems.map(wrappedItem => {
        const item = wrappedItem.item;
        if (wrappedItem.retryAttempts > 0 && item.msg) {
          if (!item.msg._metadata) {
            item.msg._metadata = {};
          }
          item.msg._metadata.failedAttempts = wrappedItem.retryAttempts;
        }
        return item;
      });
      batchFlushCallback(items, error => {
        if (error) {
          if (otherMetadata.numberOfDeletedItems > 0) {
            this.metricsCollector.addToItemsDiscardedByRetryCounter(otherMetadata.numberOfDeletedItems);
          }
          reject(error);
        } else {
          resolve(DoneState.SUCCESS);
        }
      });
    });
  }
  buildOptions(options) {
    return {
      backoffFactor: (options === null || options === void 0 ? void 0 : options.backoffFactor) || DEFAULT_POLLING_OPTIONS.backoffFactor,
      backoffJitterPercentage: (options === null || options === void 0 ? void 0 : options.backoffJitterPercentage) !== undefined ? options.backoffJitterPercentage : DEFAULT_POLLING_OPTIONS.backoffJitterPercentage,
      batchFlushSize: (options === null || options === void 0 ? void 0 : options.batchFlushSize) || DEFAULT_POLLING_OPTIONS.batchFlushSize,
      flushBeforeUnload: (options === null || options === void 0 ? void 0 : options.flushBeforeUnload) || DEFAULT_POLLING_OPTIONS.flushBeforeUnload,
      flushWaitMs: (options === null || options === void 0 ? void 0 : options.flushWaitMs) || DEFAULT_POLLING_OPTIONS.flushWaitMs,
      maxItems: (options === null || options === void 0 ? void 0 : options.maxItems) || DEFAULT_POLLING_OPTIONS.maxItems,
      maxAttempts: (options === null || options === void 0 ? void 0 : options.maxAttempts) || DEFAULT_POLLING_OPTIONS.maxAttempts,
      maxRetryDelay: (options === null || options === void 0 ? void 0 : options.maxRetryDelay) || DEFAULT_POLLING_OPTIONS.maxRetryDelay,
      minRetryDelay: (options === null || options === void 0 ? void 0 : options.minRetryDelay) || DEFAULT_POLLING_OPTIONS.minRetryDelay
    };
  }
}