import {
  Action,
  LoginFieldError,
  LoginGlobalError,
  JWT,
  CheckUserNameRedirectType,
  CheckUserNamePasswordlessType,
  CheckUserNameIdentities,
  RemoveRememberedAccountResponseTypes,
} from '../types';
import { Locale } from '../utilities/marketing-consent';

export interface RememberedAccount {
  accountId: string;
  email: string;
  displayName?: string;
  avatarUrl?: string;
}

export interface State {
  isUsernameChecked: boolean;
  passwordless: CheckUserNamePasswordlessType;
  isSocialBlocked: boolean;
  usernameExists: boolean;
  isCaptchaRequired: boolean;
  isCheckingUsername: boolean;
  isErrorShown: boolean;
  error?: LoginGlobalError;
  fieldErrors: LoginFieldError;
  isLoggingIn: boolean;
  redirectUrl: string;
  redirectType: CheckUserNameRedirectType | null;
  restrictedEmail: boolean;
  nextAttempt: number;
  showResetHint: boolean;
  localePromise?: Promise<Locale>;
  recoveryToken?: JWT;
  identities?: CheckUserNameIdentities;
  /** Whether the remember me feature is enabled. */
  rememberMeEnabled: boolean;
  /** List of remembered accounts. */
  rememberedAccounts: RememberedAccount[];
  /** Whether or not the remember me checkbox is checked. */
  isRememberMeChecked: boolean;
  /** Used to show the user feedback based on the response from the backend. */
  rememberedAccountsResponseCodes: RemoveRememberedAccountResponseTypes[];
}

export const defaultState: State = {
  isUsernameChecked: false,
  passwordless: CheckUserNamePasswordlessType.Disallowed,
  isCaptchaRequired: false,
  isCheckingUsername: false,
  isErrorShown: false,
  fieldErrors: {},
  isLoggingIn: false,
  redirectUrl: '',
  redirectType: null,
  restrictedEmail: false,
  nextAttempt: 1,
  showResetHint: false,
  usernameExists: false,
  isSocialBlocked: false,
  rememberMeEnabled: false,
  rememberedAccounts: [],
  isRememberMeChecked: false,
  rememberedAccountsResponseCodes: [],
};

const login = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'LOGIN_REQUEST_CHECK_USERNAME':
      return {
        ...state,
        isCheckingUsername: true,
        isErrorShown: false,
        fieldErrors: {},
        identities: undefined,
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_REDIRECT':
      return {
        ...state,
        isCheckingUsername: false,
        isErrorShown: false,
        fieldErrors: {},
        isUsernameChecked: false,
        usernameExists: true,
        redirectUrl: action.redirectUrl,
        redirectType: action.redirectType,
        passwordless: CheckUserNamePasswordlessType.Disallowed,
        isSocialBlocked: false,
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_SIGNUP':
      return {
        ...state,
        isCheckingUsername: false,
        isErrorShown: false,
        fieldErrors: {},
        isUsernameChecked: true,
        usernameExists: false,
        redirectUrl: '',
        redirectType: null,
        passwordless: CheckUserNamePasswordlessType.Disallowed,
        isSocialBlocked: false,
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_NO_ACTION':
      return {
        ...state,
        isCheckingUsername: false,
        isErrorShown: false,
        fieldErrors: {},
        isUsernameChecked: true,
        usernameExists: true,
        redirectUrl: '',
        redirectType: null,
        passwordless: action.passwordless,
        isSocialBlocked: action.social === 'disallowed',
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_SOCIAL_LOGIN':
      return {
        ...state,
        isCheckingUsername: false,
        isErrorShown: false,
        fieldErrors: {},
        isUsernameChecked: true,
        usernameExists: true,
        redirectUrl: '',
        redirectType: null,
        passwordless: action.passwordless,
        identities: action.identities,
        isSocialBlocked: false,
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_FAILED':
      return {
        ...state,
        isErrorShown: false,
        isCheckingUsername: false,
        fieldErrors: { email: action.error },
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_ERROR':
      return {
        ...state,
        isCheckingUsername: false,
        isErrorShown: true,
        error: action.error,
      };
    case 'LOGIN_RESET':
      return {
        ...state,
        isCaptchaRequired: false,
        isSocialBlocked: false,
        isUsernameChecked: false,
        usernameExists: false,
        isErrorShown: false,
        nextAttempt: 1,
        fieldErrors: {},
        identities: undefined,
      };
    case 'LOGIN_REQUEST_USERNAME_PASSWORD_LOGIN':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: true,
        fieldErrors: {},
      };
    case 'LOGIN_REQUEST_USERNAME_PASSWORD_LOGIN_FAILED':
      return {
        ...state,
        isErrorShown: true,
        isLoggingIn: false,
        error: action.error,
      };
    case 'LOGIN_REQUEST_USERNAME_PASSWORD_LOGIN_SUCCESS':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: false,
        fieldErrors: {},
        redirectUrl: action.redirectUrl,
      };
    case 'LOGIN_REQUEST_PASSWORDLESS_LOGIN':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: true,
        fieldErrors: {},
      };
    case 'LOGIN_REQUEST_PASSWORDLESS_LOGIN_FAILED':
      return {
        ...state,
        isErrorShown: true,
        isLoggingIn: false,
        error: action.error,
      };
    case 'LOGIN_REQUEST_PASSWORDLESS_LOGIN_SUCCESS':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: false,
        fieldErrors: {},
      };
    case 'LOGIN_REDIRECT_USER':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: false,
        fieldErrors: {},
        redirectUrl: action.redirectUrl,
      };
    case 'LOGIN_SHOW_LOGIN_FORM_ERRORS':
      return {
        ...state,
        fieldErrors: action.errors,
      };
    case 'UPDATE_LOGIN_ATTEMPT_DETAILS':
      return {
        ...state,
        nextAttempt: action.nextAttempt,
      };
    case 'LOGIN_UPDATE_CAPTCHA_REQUIREMENT':
      return {
        ...state,
        isCaptchaRequired: action.isCaptchaRequired,
      };
    case 'LOGIN_SET_STATE':
      return {
        ...state,
        ...(action.state.login?.error && { error: action.state.login.error }),
        ...(action.state.login?.fieldErrors && { fieldErrors: action.state.login.fieldErrors }),
        ...(action.state.login?.recoveryToken && {
          recoveryToken: action.state.login?.recoveryToken,
        }),
      };

    case 'REMOVE_REMEMBERED_ACCOUNT_REQUEST_INITIATE': {
      const { accountIds } = action.payload;

      return {
        ...state,
        rememberedAccounts: state.rememberedAccounts.filter(
          account => !accountIds.includes(account.accountId)
        ),
      };
    }
    case 'REMOVE_REMEMBERED_ACCOUNT_REQUEST_SUCCESS':
    case 'REMOVE_REMEMBERED_ACCOUNT_REQUEST_FAILED':
      // Update the state based on the response from the backend, which updates the UI.
      return {
        ...state,
        rememberedAccountsResponseCodes: [
          ...(state.rememberedAccountsResponseCodes || []),
          action.payload.response,
        ],
      };

    case 'RESET_REMEMBERED_ACCOUNT_RESPONSE_CODES':
      return {
        ...state,
        rememberedAccountsResponseCodes: [],
      };
      break;
    case 'REMEMBER_ME_CHECKBOX_UPDATE':
      return {
        ...state,
        isRememberMeChecked: action.payload.enabled,
      };
    case 'REMEMBER_ME_CHECKBOX_TOGGLE':
      return {
        ...state,
        isRememberMeChecked: !state.isRememberMeChecked,
      };
    case 'RESET_REMEMBER_ME_STATE': {
      return {
        ...state,
        isRememberMeChecked: false,
        isCheckingUsername: false,
        isUsernameChecked: false,
      };
    }
    default:
      return state;
  }
};

export default login;
