"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DynamicConfig = /** @class */ (function () {
    function DynamicConfig(configName, configValue, ruleID, evaluationDetails, secondaryExposures, allocatedExperimentName, onDefaultValueFallback) {
        if (secondaryExposures === void 0) { secondaryExposures = []; }
        if (allocatedExperimentName === void 0) { allocatedExperimentName = ''; }
        if (onDefaultValueFallback === void 0) { onDefaultValueFallback = null; }
        this._onDefaultValueFallback = null;
        this.value = JSON.parse(JSON.stringify(configValue !== null && configValue !== void 0 ? configValue : {}));
        this._name = configName;
        this._ruleID = ruleID !== null && ruleID !== void 0 ? ruleID : '';
        this._secondaryExposures = secondaryExposures;
        this._allocatedExperimentName = allocatedExperimentName;
        this._evaluationDetails = evaluationDetails;
        this._onDefaultValueFallback = onDefaultValueFallback;
    }
    DynamicConfig.prototype.get = function (key, defaultValue, typeGuard) {
        var _a, _b;
        var val = this.getValue(key, defaultValue);
        if (val == null) {
            return defaultValue;
        }
        var expectedType = Array.isArray(defaultValue)
            ? 'array'
            : typeof defaultValue;
        var actualType = Array.isArray(val) ? 'array' : typeof val;
        if (typeGuard) {
            if (typeGuard(val)) {
                return val;
            }
            (_a = this._onDefaultValueFallback) === null || _a === void 0 ? void 0 : _a.call(this, this, key, expectedType, actualType);
            return defaultValue;
        }
        if (defaultValue == null || expectedType === actualType) {
            return val;
        }
        (_b = this._onDefaultValueFallback) === null || _b === void 0 ? void 0 : _b.call(this, this, key, expectedType, actualType);
        return defaultValue;
    };
    DynamicConfig.prototype.getValue = function (key, defaultValue) {
        if (key == null) {
            return this.value;
        }
        if (defaultValue == null) {
            defaultValue = null;
        }
        if (this.value[key] == null) {
            return defaultValue;
        }
        return this.value[key];
    };
    return DynamicConfig;
}());
exports.default = DynamicConfig;
