/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */

import { B400, N0, N30A, N500, N700, N800 } from '@atlaskit/theme/colors';
export var flagBackgroundColor = {
  error: 'color.background.danger.bold',
  info: 'color.background.neutral.bold',
  normal: 'elevation.surface.overlay',
  success: 'color.background.success.bold',
  warning: 'color.background.warning.bold'
};
export var flagIconColor = {
  error: "var(--ds-icon-inverse, ".concat(N0, ")"),
  info: "var(--ds-icon-inverse, ".concat(N0, ")"),
  normal: "var(--ds-icon-subtle, ".concat(N500, ")"),
  success: "var(--ds-icon-inverse, ".concat(N0, ")"),
  warning: "var(--ds-icon-warning-inverse, ".concat(N700, ")")
};

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
export var flagTextColor = {
  error: 'color.text.inverse',
  info: 'color.text.inverse',
  normal: 'color.text',
  success: 'color.text.inverse',
  warning: 'color.text.warning.inverse'
};
export var flagTextColorToken = {
  error: "var(--ds-text-inverse, ".concat(N0, ")"),
  info: "var(--ds-text-inverse, ".concat(N0, ")"),
  normal: "var(--ds-text, ".concat(N800, ")"),
  success: "var(--ds-text-inverse, ".concat(N0, ")"),
  warning: "var(--ds-text-warning-inverse, ".concat(N700, ")")
};
// TODO: DSP-2519 Interaction tokens should be used for hovered and pressed states
// https://product-fabric.atlassian.net/browse/DSP-2519
export var actionBackgroundColor = {
  success: {
    default: "var(--ds-background-inverse-subtle, ".concat(N30A, ")"),
    active: "var(--ds-background-inverse-subtle-pressed, ".concat(N30A, ")"),
    pressed: "var(--ds-background-inverse-subtle-hovered, ".concat(N30A, ")")
  },
  info: {
    default: "var(--ds-background-inverse-subtle, ".concat(N30A, ")"),
    active: "var(--ds-background-inverse-subtle-pressed, ".concat(N30A, ")"),
    pressed: "var(--ds-background-inverse-subtle-hovered, ".concat(N30A, ")")
  },
  error: {
    default: "var(--ds-background-inverse-subtle, ".concat(N30A, ")"),
    active: "var(--ds-background-inverse-subtle-pressed, ".concat(N30A, ")"),
    pressed: "var(--ds-background-inverse-subtle-hovered, ".concat(N30A, ")")
  },
  warning: {
    default: "var(--ds-background-inverse-subtle, ".concat(N30A, ")"),
    active: "var(--ds-background-inverse-subtle-pressed, ".concat(N30A, ")"),
    pressed: "var(--ds-background-inverse-subtle-hovered, ".concat(N30A, ")")
  },
  normal: {
    default: 'none',
    active: 'none',
    pressed: 'none'
  }
};
export var actionTextColor = {
  success: "var(--ds-text-inverse, ".concat(N0, ")"),
  info: "var(--ds-text-inverse, ".concat(N0, ")"),
  error: "var(--ds-text-inverse, ".concat(N0, ")"),
  warning: "var(--ds-text-warning-inverse, ".concat(N700, ")"),
  normal: "var(--ds-link, ".concat(B400, ")")
};