import { GuardPolicy, StoreType } from './constants';
import { NoIndexedDbError, shouldIgnoreResilienceDbError } from './errors';
import IndexedDbConnector from './IndexedDbConnector';
import MemoryDb from './MemoryDb';
import { createOptionsWithDefaults } from './util';
export default class DbDelegator {
  constructor(namespace, options = {}) {
    this.namespace = namespace;
    this.options = createOptionsWithDefaults(options);
    if (!namespace || namespace.length === 0 || typeof namespace !== 'string') {
      throw new Error('Invalid namespace provided');
    }
    this.resilience = this.getResilienceMechanism(namespace, options);
  }
  getResilienceMechanism(namespace, options) {
    if (!options.useMemory) {
      try {
        return new IndexedDbConnector(namespace, options);
      } catch (error) {
        if (!(error instanceof NoIndexedDbError)) {
          var _options$logger;
          (_options$logger = options.logger) === null || _options$logger === void 0 || _options$logger.warn('Attempted to create IndexedDbResilience but failed. Using memory instead.');
        }
      }
    }
    return new MemoryDb(this.namespace, options);
  }
  addItem(item, options, policy = GuardPolicy.ABANDON) {
    return this.runOrFailOver(() => this.resilience.addItem(item, options, policy));
  }
  bulkAddItem(itemOptions, policy = GuardPolicy.ABANDON) {
    return this.runOrFailOver(() => this.resilience.bulkAddItem(itemOptions, policy));
  }
  getItems(count) {
    return this.runOrFailOver(() => this.resilience.getItems(count));
  }
  deleteItems(itemIds) {
    return this.runOrFailOver(() => this.resilience.deleteItems(itemIds));
  }
  getItemCount() {
    return this.runOrFailOver(() => this.resilience.getItemCount());
  }
  processItems(processFn, count) {
    return this.runOrFailOver(() => this.resilience.processItems(processFn, count));
  }
  storeType() {
    return this.resilience.storeType();
  }
  async runOrFailOver(runnable) {
    try {
      return await runnable();
    } catch (error) {
      if (shouldIgnoreResilienceDbError(error)) {
        throw error;
      } else if (this.resilience.storeType() === StoreType.INDEXEDDB) {
        await this.failOver();
      }
      return runnable();
    }
  }
  async failOver() {
    // Attempt to get any events we can and put them into memory
    const oldResilience = this.resilience;
    const newResilience = new MemoryDb(this.namespace, this.options);
    this.resilience = newResilience;
    try {
      const {
        items
      } = await oldResilience.getItems(this.options.maxEventLimit);
      if (items.length > 0) {
        const bulkAddItemsResponse = await newResilience.bulkAddItemWrapperType(items, GuardPolicy.IGNORE);
        const addedItemIds = bulkAddItemsResponse.items.map(item => item.id);
        await oldResilience.deleteItems(addedItemIds);
      }
    } catch (error) {
      this.options.logger.warn('Unexpected error from ResilienceDb, switching to MemoryDb');
      // Dont need to catch. We are just getting events if we can
    }
  }
}