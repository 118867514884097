import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-classes-per-file */

import { buildActionFields } from '../eventBuilder';
import { equals, omit } from '../objectUtils';
import { CompressionRule } from './compressionRule';
export default class EventCompressor {
  constructor(compressionRules = []) {
    _defineProperty(this, "canCompress", event => this.compressionRules.some(compressionRule => compressionRule.canCompress(event)));
    _defineProperty(this, "compress", events => {
      const groups = this.createGroups(events);
      return groups.reduce((allCompressedEvents, group) => {
        const groupCompressedEvents = this.compressGroup(group);
        return allCompressedEvents.concat(groupCompressedEvents);
      }, []);
    });
    _defineProperty(this, "createGroups", events =>
    // Group events based on contextual fields. These fields are anything that is added by the client itself,
    // rather than passed in by the caller (eg. product, org. and tenant info)
    events.reduce((groups, event) => {
      const matchingCompressor = this.compressionRules.find(compressor => compressor.canCompress(event));
      let contextFields;
      if (matchingCompressor) {
        const actionFields = buildActionFields(event, event.eventType);
        contextFields = omit(event, Object.keys(actionFields));
      }
      const matchingGroup = groups.find(group => matchingCompressor === group.compressor && equals(contextFields, group.contextFields));
      if (matchingGroup) {
        matchingGroup.events.push(event);
      } else {
        groups.push({
          contextFields,
          compressor: matchingCompressor,
          events: [event]
        });
      }
      return groups;
    }, []));
    _defineProperty(this, "compressGroup", group => {
      // If this group doesn't have any compressor, then the event args are already in their final format
      if (!group.compressor) {
        return group.events;
      }
      try {
        // Run the compressor on the group to generate some new events.
        // The compression function is only expected to return the action fields for each
        // event that it generates, since all other fields are generated by the client.
        const compressedEventActionFields = group.compressor.compress(group.events);

        // Add the context fields to each of the resulting events to inflate them into a full action event
        return compressedEventActionFields.map(actionFields => _objectSpread(_objectSpread({}, actionFields), group.contextFields));
      } catch (e) {
        // If we fail to compress the events, then just fall back the uncompressed events
        // so that no data is lost. This can happen if the compression function throws an error
        // or returns some invalid event payloads.
        // eslint-disable-next-line no-console
        console.warn('Failed to compress some analytics events. ' + `Error: ${e.message}. Sending ${group.events.length} uncompressed events instead`);
        return group.events;
      }
    });
    if (!Array.isArray(compressionRules)) {
      throw new Error('Event compressors must be constructed with an array of CompressionRules');
    }
    if (!compressionRules.every(rule => rule instanceof CompressionRule)) {
      throw new Error('Event compressors can only be constructed with instances of CompressionRule');
    }
    this.compressionRules = compressionRules;
  }
}