import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Metric } from '../metric/metric';
import { PageLoadMetric } from '../metric/page-load-metric';
import { PageSegmentLoadMetric } from '../metric/page-segment-load';
import { BMEventsType } from '../types';
export var pageLoad = function pageLoad(config) {
  return new PageLoadMetric(_objectSpread(_objectSpread({}, config), {}, {
    type: BMEventsType.PAGE_LOAD
  }));
};
export var pageSegmentLoad = function pageSegmentLoad(config) {
  return new PageSegmentLoadMetric(_objectSpread(_objectSpread({}, config), {}, {
    type: BMEventsType.PAGE_SEGMENT_LOAD
  }));
};
export var interaction = function interaction(config) {
  return new Metric(_objectSpread(_objectSpread({}, config), {}, {
    type: BMEventsType.INLINE_RESULT
  }));
};
export var custom = function custom(config) {
  return new Metric(_objectSpread(_objectSpread({}, config), {}, {
    type: BMEventsType.CUSTOM
  }));
};