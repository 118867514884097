import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["item"],
  _excluded2 = ["items"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GuardPolicy, StoreType } from './constants';
import { GET_ITEM_COUNT, VISIBILITY_TIMEOUT } from './defaults';
import { CallbackProcessingError, InvalidPolicyError } from './errors';
import MemoryDbEventCountGuard from './MemoryDbEventCountGuard';
import { convertToItemWrapper, createOptionsWithDefaults } from './util';
export default class MemoryDb {
  constructor(namespace, options = {}) {
    this.namespace = namespace;
    this.memoryStore = [];
    this.options = createOptionsWithDefaults(options);
    /**
     * This class will enforce the number of analytics events we can store in our MemoryDb
     */
    this.globalEventLimitGuard = new MemoryDbEventCountGuard(this.options.maxEventLimit, {
      addItems: this.addItems.bind(this),
      getItemCount: this.getItemCount.bind(this),
      evictEventsIfNeeded: this.evictEventsIfNeeded.bind(this)
    });
  }

  /**
   * This method is used mainly to write new events to MemoryDB and uses the MEMORY_DB_GUARD_POLICY.EVICT_OLDEST_IF_LIMIT_EXECEEDED policy
   * to removes oldest events when limit is reached.
   */
  async addItem(item, options = {}, policy = GuardPolicy.ABANDON) {
    if (policy === GuardPolicy.IGNORE) {
      throw new InvalidPolicyError(policy, 'IndexedDbConnector#addItem');
    }
    const storedValue = convertToItemWrapper(item, this.namespace, options);
    // Delegated responsiblity to event limit guard to insert to memory stored
    const bulkAddItemsResult = await this.globalEventLimitGuard.insertItemsToMemoryStore([storedValue], policy);
    return Promise.resolve({
      item: bulkAddItemsResult.items[0],
      numberOfEvictedItems: bulkAddItemsResult.numberOfEvictedItems
    });
  }
  bulkAddItem(itemOptions, policy = GuardPolicy.ABANDON) {
    const items = itemOptions.map(_ref => {
      let {
          item
        } = _ref,
        addOptions = _objectWithoutProperties(_ref, _excluded);
      return convertToItemWrapper(item, this.namespace, addOptions);
    });
    return this.bulkAddItemWrapperType(items, policy);
  }

  /**
   * This method is used mainly to write events to MemoryDB when unknown errors are thrown from IndexedDB.
   */
  bulkAddItemWrapperType(items, policy = GuardPolicy.ABANDON) {
    return Promise.resolve(this.globalEventLimitGuard.insertItemsToMemoryStore(items, policy));
  }
  getItems(count = GET_ITEM_COUNT) {
    return Promise.resolve(this.synchronousGetItems(count));
  }
  synchronousGetItems(count = GET_ITEM_COUNT) {
    const fixedCount = count > 0 ? count : GET_ITEM_COUNT;
    const now = Date.now();
    const wrappedItems = [];
    const itemsToRemove = [];
    for (let wrappedItem of this.memoryStore) {
      if (wrappedItem.timeToBeProcessedAfter <= now) {
        wrappedItems.push(_objectSpread({}, wrappedItem));
        // Mutates the item in the memoryStore array, but not whats inside of wrappedItems
        wrappedItem.timeToBeProcessedAfter += VISIBILITY_TIMEOUT;
        wrappedItem.retryAttempts += 1;
        if (wrappedItem.retryAttempts >= this.options.maxAttempts) {
          itemsToRemove.push(wrappedItem);
        }
      }
      if (wrappedItems.length >= fixedCount) {
        break;
      }
    }
    itemsToRemove.forEach(item => {
      const index = this.memoryStore.indexOf(item);
      this.memoryStore.splice(index, 1);
    });
    return {
      items: wrappedItems,
      numberOfDeletedItems: itemsToRemove.length
    };
  }
  deleteItems(itemIds) {
    this.memoryStore = this.memoryStore.filter(item => !itemIds.includes(item.id));
    return Promise.resolve(void 0);
  }
  getItemCount() {
    const now = Date.now();
    const count = this.memoryStore.filter(item => item.timeToBeProcessedAfter <= now).length;
    return Promise.resolve(count);
  }
  async processItems(processFn, count) {
    const _this$synchronousGetI = this.synchronousGetItems(count),
      {
        items
      } = _this$synchronousGetI,
      partialGetResult = _objectWithoutProperties(_this$synchronousGetI, _excluded2);
    const itemIds = items.map(i => i.id);
    try {
      const result = await processFn(items, partialGetResult);
      await this.deleteItems(itemIds);
      return result;
    } catch (error) {
      throw new CallbackProcessingError(error);
    }
  }
  storeType() {
    return StoreType.MEMORY;
  }

  /**
   * This function is adding items to the tail of the memoryStore and as it adds new items it keeps the
   * memory store sorted by timeAdded property. This makes evictions easier and adding elements to
   * memoryStore much faster.
   *
   * @param itemsToAdd
   */
  addItems(itemsToAdd) {
    this.memoryStore.push(...itemsToAdd);

    // Sorting everytime, intentionally.
    this.memoryStore.sort(function (a, b) {
      return a.timeAdded - b.timeAdded;
    });
  }

  /**
   * This function checks the number of events currently in AWC MemoryDb and if necessary,
   * will evict the oldest events in favour of the events we want to add.
   *
   * @param countOfEventsToAdd - The number of events we are proposing to add.
   */
  evictEventsIfNeeded(eventLimit) {
    const numberOfEventsInDb = this.memoryStore.length;
    // The number of analytics events currently in MemoryDb and
    // the Z events we are proposing to add will exceed our event count limit.
    if (numberOfEventsInDb > eventLimit) {
      const m = numberOfEventsInDb - eventLimit;

      // Removing oldest M events
      this.memoryStore.splice(0, m);
      return m;
    }
    return 0;
  }
}