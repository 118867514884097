"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.bindAll = void 0;
var bind_1 = require("./bind");
function toOptions(value) {
    if (typeof value === 'undefined') {
        return undefined;
    }
    if (typeof value === 'boolean') {
        return {
            capture: value,
        };
    }
    return value;
}
function getBinding(original, sharedOptions) {
    if (sharedOptions == null) {
        return original;
    }
    var binding = __assign(__assign({}, original), { options: __assign(__assign({}, toOptions(sharedOptions)), toOptions(original.options)) });
    return binding;
}
function bindAll(target, bindings, sharedOptions) {
    var unbinds = bindings.map(function (original) {
        var binding = getBinding(original, sharedOptions);
        return (0, bind_1.bind)(target, binding);
    });
    return function unbindAll() {
        unbinds.forEach(function (unbind) { return unbind(); });
    };
}
exports.bindAll = bindAll;
