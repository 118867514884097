"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Layer = /** @class */ (function () {
    function Layer(name, layerValue, ruleID, evaluationDetails, logParameterFunction, secondaryExposures, undelegatedSecondaryExposures, allocatedExperimentName, explicitParameters) {
        if (logParameterFunction === void 0) { logParameterFunction = null; }
        if (secondaryExposures === void 0) { secondaryExposures = []; }
        if (undelegatedSecondaryExposures === void 0) { undelegatedSecondaryExposures = []; }
        if (allocatedExperimentName === void 0) { allocatedExperimentName = ''; }
        if (explicitParameters === void 0) { explicitParameters = []; }
        this._logParameterFunction = logParameterFunction;
        this._name = name;
        this._value = JSON.parse(JSON.stringify(layerValue !== null && layerValue !== void 0 ? layerValue : {}));
        this._ruleID = ruleID !== null && ruleID !== void 0 ? ruleID : '';
        this._evaluationDetails = evaluationDetails;
        this._secondaryExposures = secondaryExposures;
        this._undelegatedSecondaryExposures = undelegatedSecondaryExposures;
        this._allocatedExperimentName = allocatedExperimentName;
        this._explicitParameters = explicitParameters;
    }
    Layer._create = function (name, value, ruleID, evaluationDetails, logParameterFunction, secondaryExposures, undelegatedSecondaryExposures, allocatedExperimentName, explicitParameters) {
        if (logParameterFunction === void 0) { logParameterFunction = null; }
        if (secondaryExposures === void 0) { secondaryExposures = []; }
        if (undelegatedSecondaryExposures === void 0) { undelegatedSecondaryExposures = []; }
        if (allocatedExperimentName === void 0) { allocatedExperimentName = ''; }
        if (explicitParameters === void 0) { explicitParameters = []; }
        return new Layer(name, value, ruleID, evaluationDetails, logParameterFunction, secondaryExposures, undelegatedSecondaryExposures, allocatedExperimentName, explicitParameters);
    };
    Layer.prototype.get = function (key, defaultValue, typeGuard) {
        var _this = this;
        var val = this._value[key];
        if (val == null) {
            return defaultValue;
        }
        var logAndReturn = function () {
            _this._logLayerParameterExposure(key);
            return val;
        };
        if (typeGuard) {
            return typeGuard(val) ? logAndReturn() : defaultValue;
        }
        if (defaultValue == null) {
            return logAndReturn();
        }
        if (typeof val === typeof defaultValue &&
            Array.isArray(defaultValue) === Array.isArray(val)) {
            return logAndReturn();
        }
        return defaultValue;
    };
    Layer.prototype.getValue = function (key, defaultValue) {
        if (defaultValue == undefined) {
            defaultValue = null;
        }
        var val = this._value[key];
        if (val != null) {
            this._logLayerParameterExposure(key);
        }
        return val !== null && val !== void 0 ? val : defaultValue;
    };
    Layer.prototype._logLayerParameterExposure = function (parameterName) {
        var _a;
        (_a = this._logParameterFunction) === null || _a === void 0 ? void 0 : _a.call(this, this, parameterName);
    };
    return Layer;
}());
exports.default = Layer;
