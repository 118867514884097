import { useEffect } from 'react';
import { PORTAL_MOUNT_EVENT, PORTAL_UNMOUNT_EVENT } from '../../constants';
import firePortalEvent from '../utils/portal-custom-event';
var useFirePortalEvent = function useFirePortalEvent(zIndex) {
  var zIndexNumber = Number(zIndex);
  useEffect(function () {
    firePortalEvent(PORTAL_MOUNT_EVENT, zIndexNumber);
    return function () {
      firePortalEvent(PORTAL_UNMOUNT_EVENT, zIndexNumber);
    };
  }, [zIndexNumber]);
};
export default useFirePortalEvent;