import _typeof from "@babel/runtime/helpers/typeof";
import { NON_BOOLEAN_VALUE } from '../constants';
var startsAsObject = function startsAsObject(value) {
  try {
    if (value.charAt(0) === '{') {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};
var isObject = function isObject(value) {
  return value && _typeof(value) === 'object' && value.constructor === Object;
};
var isString = function isString(input) {
  var result;
  try {
    result = typeof input === 'string' || input instanceof String;
  } catch (err) {
    // swallow any errors
    result = false;
  }
  return result;
};
export var shouldRedactValue = function shouldRedactValue(value) {
  return isString(value) && startsAsObject(value) || isObject(value);
};
export var redactValue = function redactValue(featureFlagValue) {
  return shouldRedactValue(featureFlagValue) ? NON_BOOLEAN_VALUE : featureFlagValue;
};