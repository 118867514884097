function abort(event) {
  event.preventDefault();
  event.stopPropagation();
}
var tabKeyCode = 9;
function onKey(event) {
  // Allowing tab so that a user can move focus away
  if (event.keyCode === tabKeyCode) {
    return;
  }
  abort(event);
}
var block = {
  onMouseDownCapture: abort,
  onMouseUpCapture: abort,
  // because we have tabIndex = -1 when disabled,
  // keyboard events can only occur when there is an overlay
  onKeyDownCapture: onKey,
  onKeyUpCapture: onKey,
  onTouchStartCapture: abort,
  onTouchEndCapture: abort,
  onPointerDownCapture: abort,
  onPointerUpCapture: abort,
  onClickCapture: abort,
  // Just smashing the existing onClick for good measure
  onClick: abort
};
var doNotBlock = {};
export default function blockEvents(_ref) {
  var isInteractive = _ref.isInteractive;
  return isInteractive ? doNotBlock : block;
}