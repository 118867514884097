import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import getGlobalTheme from './get-global-theme';
import ThemeMutationObserver from './theme-mutation-observer';

/**
 * A React hook which returns the current themes and color-mode set on `<html>`.
 *
 * @example
 * ```
 * const { colorMode, dark, light, spacing, typography } = useThemeObserver();
 *
 * // Performing side effects when it changes
 * useEffect(() => {
 *   console.log(`The color mode has changed to ${theme.colorMode}`);
 * }, [theme.colorMode]);
 * ```
 */
var useThemeObserver = function useThemeObserver() {
  var _useState = useState(getGlobalTheme()),
    _useState2 = _slicedToArray(_useState, 2),
    theme = _useState2[0],
    setTheme = _useState2[1];
  useEffect(function () {
    var observer = new ThemeMutationObserver(function (theme) {
      return setTheme(theme);
    });
    observer.observe();
    return function () {
      return observer.disconnect();
    };
  }, []);
  return theme;
};
export default useThemeObserver;