"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCAL_STORAGE_KEYS = exports.STATSIG_LOCAL_STORAGE_LOGGING_REQUEST_KEY = exports.STATSIG_STABLE_ID_KEY = exports.INTERNAL_STORE_KEY = void 0;
exports.INTERNAL_STORE_KEY = 'STATSIG_STORE';
exports.STATSIG_STABLE_ID_KEY = 'STATSIG_STABLE_ID';
exports.STATSIG_LOCAL_STORAGE_LOGGING_REQUEST_KEY = 'STATSIG_LOGS';
exports.LOCAL_STORAGE_KEYS = {
    INTERNAL_STORE_KEY: true,
    STATSIG_STABLE_ID_KEY: true,
    STATSIG_LOCAL_STORAGE_LOGGING_REQUEST_KEY: true,
};
