import warnOnce from './warn-once';

/**
 * Will print an API deprecation warning message in the console once per session.
 *
 * @param packageName       The package of the API being deprecated, eg `@atlaskit/button`
 * @param api               The API being deprecated - a component, API, prop
 * @param additionalMessage Additional guidance / next steps if applicable
 *
 * @example
 *
 * ```js
 * deprecationWarning('@atlaskit/button', 'className prop', 'This API will stop working in the next major version.')
 * ```
 */
export default function deprecationWarning(packageName, api, additionalMessage) {
  warnOnce("[".concat(packageName, "]: The ").concat(api, " is deprecated.").concat(additionalMessage && " ".concat(additionalMessage)));
}

/**
 * Logs a prop deprecation warning to console once during a session.
 *
 * @param packageName Use `process.env._PACKAGE_NAME_` instead of a static string.
 * @param propName Prop that is deprecated.
 * @param predicate If true the deprecation warning will be logged to console.
 * @param deprecationAnnouncementOnDAC Link to the public announcement on DAC.
 */
export function propDeprecationWarning(packageName, propName, predicate, deprecationAnnouncementOnDAC) {
  if (typeof process !== 'undefined' && process.env.NODE_ENV === 'development' && predicate) {
    warnOnce("[".concat(packageName, "]: The ").concat(propName, " prop is deprecated and will be removed, please migrate away.\nPublic announcement: ").concat(deprecationAnnouncementOnDAC));
  }
}