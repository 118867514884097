import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export var customData = function customData(_config, data) {
  var custom = {};
  var changed = false;
  if (data.custom) {
    Object.entries(data.custom).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];
      changed = true;
      custom["custom:".concat(key)] = value;
    });
  }
  return changed ? custom : null;
};