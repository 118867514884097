import { redactValue } from './common/utils';
export { redactValue } from './common/utils';
export var allFeatureFlagsAccessed = new Map();
export var currentFeatureFlagsAccessed = new Map();

/**
 * Used for tracking accessed feature flags in "@atlassian/jira-feature-flagging".
 * */
export var addFeatureFlagAccessed = function addFeatureFlagAccessed(featureFlagName, featureFlagValue) {
  try {
    // Inform Criterion about FF being accessed
    if (globalThis.__CRITERION__ && typeof globalThis.__CRITERION__.addFeatureFlagAccessed === 'function') {
      globalThis.__CRITERION__.addFeatureFlagAccessed(featureFlagName, featureFlagValue);
    }
  } catch (_e) {
    // ignore the criterion error
  }
  var value = redactValue(featureFlagValue);
  allFeatureFlagsAccessed.set(featureFlagName, value);
  currentFeatureFlagsAccessed.set(featureFlagName, value);
};