export var initialPageLoadMetrics = function initialPageLoadMetrics() {
  var metrics = {};
  performance.getEntriesByType('paint').forEach(function (entry) {
    if (entry.name === 'first-paint') {
      metrics['metric:fp'] = Math.round(entry.startTime);
    }
    if (entry.name === 'first-contentful-paint') {
      metrics['metric:fcp'] = Math.round(entry.startTime);
    }
  });
  return metrics;
};