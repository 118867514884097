import { createTheme } from '../utils/create-theme';

// Create default global light theme
var _createTheme = createTheme(function () {
    return {
      mode: 'light'
    };
  }),
  Provider = _createTheme.Provider,
  Consumer = _createTheme.Consumer,
  useTheme = _createTheme.useTheme;
export { useTheme as useGlobalTheme };
var Theme = {
  Provider: Provider,
  Consumer: Consumer
};
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Theme;