import { NetworkStatusEnum } from './types';
export default class NetworkStatus {
  constructor(networkChangeCallback) {
    if (typeof window !== 'undefined') {
      window.addEventListener('offline', () => networkChangeCallback(NetworkStatusEnum.OFFLINE));
      window.addEventListener('online', () => networkChangeCallback(NetworkStatusEnum.ONLINE));
    }
  }
  getNetworkStatus() {
    if (window.navigator.onLine) {
      return NetworkStatusEnum.ONLINE;
    }
    return NetworkStatusEnum.OFFLINE;
  }
}