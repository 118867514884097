import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _excluded = ["sdkKey", "updateUserCompletionCallback", "perimeter"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { isFedRamp } from '@atlaskit/atlassian-context';
import { PerimeterType } from './types';
export var getOptionsWithDefaults = function getOptionsWithDefaults(options) {
  return _objectSpread({
    /**
     * If more federal PerimeterTypes are added in the future, this should be updated so
     * that isFedRamp() === true always returns the strictest perimeter.
     */
    perimeter: isFedRamp() ? PerimeterType.FEDRAMP_MODERATE : PerimeterType.COMMERCIAL
  }, options);
};
export var shallowEquals = function shallowEquals(objectA, objectB) {
  if (!objectA && !objectB) {
    return true;
  }
  if (!objectA || !objectB) {
    return false;
  }
  var aEntries = Object.entries(objectA);
  var bEntries = Object.entries(objectB);
  if (aEntries.length !== bEntries.length) {
    return false;
  }
  var ascendingKeyOrder = function ascendingKeyOrder(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
      key1 = _ref3[0];
    var _ref4 = _slicedToArray(_ref2, 1),
      key2 = _ref4[0];
    return key1.localeCompare(key2);
  };
  aEntries.sort(ascendingKeyOrder);
  bEntries.sort(ascendingKeyOrder);
  for (var i = 0; i < aEntries.length; i++) {
    var _aEntries$i = _slicedToArray(aEntries[i], 2),
      aValue = _aEntries$i[1];
    var _bEntries$i = _slicedToArray(bEntries[i], 2),
      bValue = _bEntries$i[1];
    if (aValue !== bValue) {
      return false;
    }
  }
  return true;
};

/**
 * This method creates an instance of StatsigUser from the given set of identifiers and
 * attributes.
 */
export var toStatsigUser = function toStatsigUser(identifiers, customAttributes) {
  var user = {
    customIDs: identifiers,
    custom: customAttributes
  };
  if (identifiers.atlassianAccountId) {
    user.userID = identifiers.atlassianAccountId;
  }
  return user;
};

/**
 * This method transforms the options given by the user into the format accepted by the Statsig
 * client.
 */
export var toStatsigOptions = function toStatsigOptions(options, initializeValues) {
  var sdkKey = options.sdkKey,
    updateUserCompletionCallback = options.updateUserCompletionCallback,
    perimeter = options.perimeter,
    restClientOptions = _objectWithoutProperties(options, _excluded);
  return _objectSpread(_objectSpread({}, restClientOptions), {}, {
    initializeValues: initializeValues,
    environment: {
      tier: options.environment
    },
    disableCurrentPageLogging: true
  }, options.updateUserCompletionCallback && {
    updateUserCompletionCallback: toStatsigUpdateUserCompletionCallback(options.updateUserCompletionCallback)
  });
};
/**
 * This method transforms an UpdateUserCompletionCallback in our own format into the format
 * accepted by the Statsig client.
 */
export var toStatsigUpdateUserCompletionCallback = function toStatsigUpdateUserCompletionCallback(callback) {
  /**
   * The duration passed to the callback indicates how long the update took, but it is deceptive
   * since it only times the Statsig code and doesn't account for all of the extra custom work we
   * do to obtain the bootstrap values. As a result, we just suppress this parameter in our own
   * callback rather than trying to keep it accurate.
   */
  return function (_duration, success, message) {
    callback(success, message);
  };
};