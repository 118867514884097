"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatsigLocalStorage = /** @class */ (function () {
    function StatsigLocalStorage() {
    }
    StatsigLocalStorage.getItem = function (key) {
        var _a;
        try {
            if (this.isStorageAccessible()) {
                return window.localStorage.getItem(key);
            }
        }
        catch (e) { }
        return (_a = this.fallbackSessionCache[key]) !== null && _a !== void 0 ? _a : null;
    };
    StatsigLocalStorage.setItem = function (key, value) {
        try {
            if (this.isStorageAccessible()) {
                window.localStorage.setItem(key, value);
                return;
            }
        }
        catch (e) { }
        this.fallbackSessionCache[key] = value;
    };
    StatsigLocalStorage.removeItem = function (key) {
        try {
            if (this.isStorageAccessible()) {
                window.localStorage.removeItem(key);
                return;
            }
        }
        catch (e) { }
        delete this.fallbackSessionCache[key];
    };
    StatsigLocalStorage.isStorageAccessible = function (ignoreDisabledOption) {
        if (ignoreDisabledOption === void 0) { ignoreDisabledOption = false; }
        if (this.canAccessStorageAccessible == null) {
            this.canAccessStorageAccessible =
                typeof Storage !== 'undefined' &&
                    typeof window !== 'undefined' &&
                    window != null &&
                    window.localStorage != null;
        }
        var canAccess = this.canAccessStorageAccessible;
        if (ignoreDisabledOption) {
            return canAccess;
        }
        return !this.disabled && canAccess;
    };
    StatsigLocalStorage.disabled = false;
    StatsigLocalStorage.fallbackSessionCache = {};
    StatsigLocalStorage.canAccessStorageAccessible = null;
    return StatsigLocalStorage;
}());
exports.default = StatsigLocalStorage;
