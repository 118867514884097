import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SegmentEventTypes } from '../integration/types';
export function attachXidToSingleEvent(item, xidCallback) {
  if (!(xidCallback && xidCallback instanceof Function)) {
    return item;
  }
  if (item.msg.type === SegmentEventTypes.PAGE || item.msg.type === SegmentEventTypes.TRACK) {
    const xid = xidCallback();
    if (xid && Array.isArray(xid)) {
      item.msg.properties = _objectSpread(_objectSpread({}, item.msg.properties), {}, {
        xid
      });
    }
  } else if (item.msg.traits && item.msg.type === SegmentEventTypes.IDENTIFY) {
    const xid = xidCallback();
    if (xid && Array.isArray(xid)) {
      item.msg.traits = _objectSpread(_objectSpread({}, item.msg.traits), {}, {
        xid
      });
    }
  }
  return item;
}
export async function attachXidToMultipleEvents(items, xidItemPromiseCallback) {
  try {
    const xidCallback = await xidItemPromiseCallback;
    return items.map(item => attachXidToSingleEvent(item, xidCallback));
  } catch (error) {
    return items;
  }
}