import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { BMEventsType } from '../../../types';
import { customTimings } from './custom';
import { ssrTimings } from './ssr';
export var appTimings = function appTimings(config, data, globalConfig) {
  var explicitTimings = config.type === BMEventsType.PAGE_LOAD && data.explicitTimings || null;
  var timings = customTimings(config, data);
  var ssr = config.type === BMEventsType.PAGE_LOAD && data.isInitial ? ssrTimings(config, data, globalConfig) : null;
  var timingsApp = (explicitTimings || timings || ssr) && _objectSpread(_objectSpread(_objectSpread({}, explicitTimings), timings), ssr);
  if (!timingsApp) {
    return null;
  }
  return {
    'timings:app': timingsApp
  };
};