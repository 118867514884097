import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { DEFAULT_DELAY_TIMEOUT, MAX_DELAY_TIMEOUT } from './defaults';
import EventDelayQueue from './eventDelayQueue';
import { StopLowPriorityEventDelayReason } from './types';
export default class EventDelayCoordinator {
  constructor(fireDelayedEvent, compressors) {
    _defineProperty(this, "push", (identifier, builtEvent, context, userInfo) => {
      this.delayQueue.push(identifier, builtEvent, context, userInfo);
    });
    _defineProperty(this, "startLowPriorityEventDelay", (timeout = DEFAULT_DELAY_TIMEOUT, callback) => {
      if (Number.isNaN(timeout) || timeout <= 0) {
        throw new Error(`Invalid timeout period: ${timeout}, must be a number greater than 0`);
      }
      if (this.delayTimeout) {
        clearTimeout(this.delayTimeout);
      }
      const delayTimeoutPeriod = timeout ? Math.min(timeout, MAX_DELAY_TIMEOUT) : MAX_DELAY_TIMEOUT;
      this.delayCallback = callback;
      this.delayTimeout = setTimeout(() => this.stopLowPriorityEventDelay(StopLowPriorityEventDelayReason.TIMEOUT), delayTimeoutPeriod);

      // If the queue is still flushing from a previous delay period, then we should cancel that flush
      // to stop it from negatively impacting the performance of the new critical section
      this.delayQueue.cancelFlush();
      this.isDelaying = true;
    });
    _defineProperty(this, "stopLowPriorityEventDelay", (reason = StopLowPriorityEventDelayReason.MANUAL) => {
      var _this$delayCallback;
      if (this.delayTimeout) {
        clearTimeout(this.delayTimeout);
        this.delayTimeout = undefined;
      }
      this.delayQueue.startFlush();
      this.isDelaying = false;
      (_this$delayCallback = this.delayCallback) === null || _this$delayCallback === void 0 || _this$delayCallback.call(this, reason);
      this.delayCallback = undefined;
    });
    _defineProperty(this, "isDelayingLowPriorityEvents", () => this.isDelaying);
    this.isDelaying = false;
    this.delayQueue = new EventDelayQueue(fireDelayedEvent, compressors);
  }
}