import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var cacheableTypes = ['script', 'link'];
var resourceTypes = ['fetch', 'xmlhttprequest'];
var CACHE_NETWORK = 'network';
var CACHE_MEMORY = 'memory';
var CACHE_DISK = 'disk';
var calculateTransferType = function calculateTransferType(type, duration, size) {
  if (!cacheableTypes.includes(type)) {
    return CACHE_NETWORK;
  }
  if ((size === undefined || size === 0) && duration === 0) {
    return CACHE_MEMORY;
  }
  if (size === 0 && duration > 0) {
    return CACHE_DISK;
  }
  if (size === undefined) {
    return null;
  }
  return CACHE_NETWORK;
};
var hasAccessToResourceSize = function hasAccessToResourceSize(url, type, hasTimingHeaders) {
  return !cacheableTypes.includes(type) || url.includes('localhost') || url.includes(window.location.hostname) || hasTimingHeaders(url);
};
var getReportedInitiatorTypes = function getReportedInitiatorTypes(xhrEnabled) {
  if (xhrEnabled) {
    return ['script', 'link', 'fetch', 'xmlhttprequest'];
  }
  return ['script', 'link', 'fetch'];
};
var withoutHeaders = function withoutHeaders() {
  return false;
};
var evaluateAccessToResourceTimings = function evaluateAccessToResourceTimings(entry) {
  if (entry.responseStart === 0 && entry.startTime > entry.responseStart) {
    return false;
  }
  return true;
};
var getEvaluatedTimingHeader = function getEvaluatedTimingHeader(entry) {
  var enabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var hasTimingHeaders = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : withoutHeaders;
  if (!enabled) {
    return null;
  }
  var manualResolution = entry.name.includes('localhost') || entry.name.includes(window.location.hostname) || hasTimingHeaders(entry.name);
  var autoResolution = evaluateAccessToResourceTimings(entry);
  return {
    m_h: manualResolution,
    a_h: autoResolution
  };
};
var getSizeObject = function getSizeObject(size) {
  return size !== undefined ? {
    size: size
  } : null;
};
var getNetworkData = function getNetworkData(item, eventStart) {
  var hasTimingHeaders = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : withoutHeaders;
  var name = item.name,
    duration = item.duration,
    size = item.transferSize,
    type = item.initiatorType,
    responseStart = item.responseStart;
  var ttfb = Math.round(responseStart - eventStart);
  if (!hasAccessToResourceSize(name, type, hasTimingHeaders)) {
    return {};
  }
  if (cacheableTypes.includes(type)) {
    var transferType = calculateTransferType(type, duration, size);
    if (!transferType) {
      return _objectSpread({
        ttfb: ttfb
      }, getSizeObject(size));
    }
    if (transferType !== CACHE_NETWORK) {
      return {
        transferType: transferType
      };
    }
    return _objectSpread({
      ttfb: ttfb,
      transferType: transferType
    }, getSizeObject(size));
  }
  return _objectSpread({
    ttfb: ttfb
  }, getSizeObject(size));
};
export var resourceTimings = function resourceTimings(_config, data, globalConfig) {
  var performance = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : window.performance;
  if (data.start === null) {
    return null;
  }
  var resources = performance.getEntriesByType('resource').filter(function (entry) {
    return entry.startTime >= data.start;
  });
  if (!resources.length) {
    return null;
  }
  var resourceTiming = {};
  var xhrFilter = globalConfig.resourceTimings.xhrFilter;
  var reportedInitiatorTypes = getReportedInitiatorTypes(!!xhrFilter);
  resources.forEach(function (item) {
    if (!reportedInitiatorTypes.includes(item.initiatorType)) {
      return;
    }
    var name = item.name,
      startTime = item.startTime,
      duration = item.duration,
      workerStart = item.workerStart,
      fetchStart = item.fetchStart,
      type = item.initiatorType;
    if (!name) {
      return;
    }
    if (type === 'xmlhttprequest' && (xhrFilter === undefined || xhrFilter(name) === false)) {
      return;
    }
    var url = resourceTypes.includes(type) ? globalConfig.resourceTimings.sanitiseEndpoints(name) : globalConfig.resourceTimings.mapResources(name);
    if (resourceTiming[url]) {
      return;
    }
    resourceTiming[url] = _objectSpread(_objectSpread({
      startTime: Math.round(startTime - data.start),
      duration: Math.round(duration),
      workerStart: Math.max(Math.round(workerStart - data.start), 0),
      fetchStart: Math.max(Math.round(fetchStart - data.start), 0),
      type: type
    }, getNetworkData(item, data.start, globalConfig.resourceTimings.hasTimingHeaders)), getEvaluatedTimingHeader(item, globalConfig.resourceTimings.experimental__reportEvaluatedTimingHeaders, globalConfig.resourceTimings.hasTimingHeaders));
  });
  return {
    'timings:resource': resourceTiming
  };
};