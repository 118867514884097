import differenceInDays from 'date-fns/differenceInDays';
import uuid from 'uuid/v4';
import Cookie, { AllAnalyticsCookies, AWCCookiesKeys } from '../cookie';
import SafeLocalStorage from '../storage/SafeLocalStorage';

// Cookie is stale, if anonymousId was generated 7 days ago.
const ANONYMOUS_ID_COOKIE_STALE_DAYS = 7;
const SAFE = 'SAFE';
const UNSAFE = 'UNSAFE';
const NOT_FOUND = 'NOT_FOUND';
const COOKIE = 'COOKIE';
const LOCAL_STORAGE = 'LOCAL_STORAGE';
export default class User {
  constructor(disableCookiePersistence) {
    this.store = new SafeLocalStorage({
      useStoragePrefix: false
    });
    this.cookie = new Cookie(disableCookiePersistence);
  }
  getUserId() {
    return this.userId || null;
  }
  setUserId(userId) {
    this.userId = userId;
  }
  getAnonymousId(customAnonymousIdGenerator) {
    /**
     * Prefer localStorage as there cant be duplicate keys, and its faster. Also update cookie, if stale.
     * If not set in localStorage, check cookies. Also set localStorage for future attempts.
     * If not in cookies, generate a new one. Set both cookie and localStorage keys.
     */
    return this.getAnonymousIdFromLocalStorageAndUpdateCookieIfStale() || this.getAnonymousIdFromCookieAndUpdateLocalStorage() || this.generateNewAnonymousId(customAnonymousIdGenerator);
  }
  setAnonymousId(anonymousId) {
    const stringifyAnonymosId = JSON.stringify(anonymousId);
    this.cookie.set(AWCCookiesKeys.AJS_ANONYMOUS_ID, stringifyAnonymosId);
    this.lastAnonymousIdCookieUpdate = Date.now();
    return this.store.setItem(AllAnalyticsCookies.AJS_ANONYMOUS_ID.getKey(), stringifyAnonymosId);
  }
  getAnonymousIdFromStorage(source) {
    const rawStorageAnonidValue = source === LOCAL_STORAGE ? this.store.getItem(AllAnalyticsCookies.AJS_ANONYMOUS_ID.getKey()) : this.cookie.get(AWCCookiesKeys.AJS_ANONYMOUS_ID);
    if (rawStorageAnonidValue) {
      const unsafeValue = {
        type: UNSAFE,
        anonymousId: rawStorageAnonidValue
      };
      try {
        const parsedAnonId = JSON.parse(rawStorageAnonidValue);
        if (parsedAnonId && typeof parsedAnonId === 'string') {
          return {
            type: SAFE,
            anonymousId: parsedAnonId
          };
        }
        return unsafeValue;
      } catch (err) {
        /*
         * Segments new Analytics-next client will store strings as strings without stringifing.
         * The legacy Segment client and AWC always stringify and parse cookies and values.
         * If the new Segment analytics-client stores a cookie and local storage value first, it will
         * breaking old clients.
         *
         * Additionally, JSON.parse can cause exceptions to be thrown in some browsers; see AAP-324.
         *
         * Intentionally swalling errors here and returning the original value found in storage to avoid ping-pong.
         */
        return unsafeValue;
      }
    }
    return {
      type: NOT_FOUND
    };
  }
  getAnonymousIdFromLocalStorageAndUpdateCookieIfStale() {
    const localStorageValue = this.getAnonymousIdFromStorage(LOCAL_STORAGE);
    let shouldUpdateCookie = false;
    if (!this.lastAnonymousIdCookieUpdate || differenceInDays(new Date(), new Date(this.lastAnonymousIdCookieUpdate)) >= ANONYMOUS_ID_COOKIE_STALE_DAYS) {
      shouldUpdateCookie = true;
    }
    switch (localStorageValue.type) {
      case SAFE:
        if (shouldUpdateCookie) {
          this.setAnonymousId(localStorageValue.anonymousId);
        }
        return localStorageValue.anonymousId;
      case UNSAFE:
        return localStorageValue.anonymousId;
      case NOT_FOUND:
      default:
        return null;
    }
  }
  getAnonymousIdFromCookieAndUpdateLocalStorage() {
    const cookieValue = this.getAnonymousIdFromStorage(COOKIE);
    switch (cookieValue.type) {
      case SAFE:
      case UNSAFE:
        this.store.setItem(AllAnalyticsCookies.AJS_ANONYMOUS_ID.getKey(), JSON.stringify(cookieValue.anonymousId));
        return cookieValue.anonymousId;
      case NOT_FOUND:
      default:
        return null;
    }
  }
  generateNewAnonymousId(customAnonymousIdGenerator = uuid) {
    const newAnonId = customAnonymousIdGenerator();
    this.setAnonymousId(newAnonId);
    return newAnonId;
  }
}