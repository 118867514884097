import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
const _excluded = ["embeddedProduct"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { tenantType } from './analyticsWebTypes';
import SafeLocalStorage from './storage/SafeLocalStorage';
const DEFAULT_DELAY = 1000 * 2; // 2 seconds
const DEFAULT_THROTTLE = 1000 * 60 * 60; // 1 hour
const DEFAULT_STORAGE_KEY = 'ui.viewed.last.sent';
export default class UIViewedEvent {
  constructor(productInfo, getContext, onEvent, settings = {}) {
    _defineProperty(this, "start", () => {
      this._startInterval();
      this._bindEventListeners();
    });
    _defineProperty(this, "stop", () => {
      this._stopInterval();
      this._unbindEventListeners();
    });
    _defineProperty(this, "resetTimers", () => {
      if (this._intervalId) {
        this.stop();
        this.start();
      }
    });
    _defineProperty(this, "_bindEventListeners", () => {
      if (typeof window !== 'undefined') {
        window.addEventListener('focus', this._focusListener);
        window.addEventListener('blur', this._blurListener);
      }
    });
    _defineProperty(this, "_unbindEventListeners", () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('focus', this._focusListener);
        window.removeEventListener('blur', this._blurListener);
      }
    });
    _defineProperty(this, "_startInterval", () => {
      clearInterval(this._intervalId);
      this._intervalId = setInterval(this._handleInterval, this._delay);
    });
    _defineProperty(this, "_stopInterval", () => {
      if (this._intervalId) {
        clearInterval(this._intervalId);
        this._intervalId = null;
      }
    });
    _defineProperty(this, "_getProductKey", (product, subproduct) => {
      let productKey = product;
      if (subproduct) {
        productKey += `-${subproduct}`;
      }
      return productKey;
    });
    _defineProperty(this, "_getLastSentTimestamp", (productKey, tenantId, userId) => {
      const json = this._parseLocalStorageData() || {};
      const u = json[userId] || {};
      const t = u[tenantId] || {};
      const p = t[productKey] || null;
      return p;
    });
    _defineProperty(this, "_parseLocalStorageData", () => {
      const data = this._safeLocalStorage.getItem(this._storageKey);
      try {
        return JSON.parse(data || '');
      } catch (error) {
        this._safeLocalStorage.removeItem(this._storageKey);
        return null;
      }
    });
    _defineProperty(this, "_setLastSentTimestamp", (productKey, tenantId, userId) => {
      const json = this._parseLocalStorageData() || {};
      const u = json[userId] || {};
      const t = u[tenantId] || {};
      t[productKey] = Date.now();
      u[tenantId] = t;
      json[userId] = u;
      this._safeLocalStorage.setItem(this._storageKey, JSON.stringify(json));
    });
    _defineProperty(this, "_shouldSendEvent", (productKey, tenantIdType, tenantId, userId) => {
      if (!document.hasFocus()) {
        return false;
      }
      if (tenantIdType !== tenantType.NONE && !tenantId || !userId) {
        return false;
      }
      const sinceLastSent = Date.now() - this._getLastSentTimestamp(productKey, tenantId, userId);
      return sinceLastSent > this._throttle;
    });
    _defineProperty(this, "_handleInterval", () => {
      const _this$_getContext = this._getContext(),
        {
          embeddedProduct
        } = _this$_getContext,
        context = _objectWithoutProperties(_this$_getContext, _excluded);
      this._sendEvent(this._product, context);
      if (embeddedProduct) {
        this._sendEvent(embeddedProduct, context, true);
      }
    });
    _defineProperty(this, "_sendEvent", (product, context, isEmbeddedProduct = false) => {
      const {
        subproduct,
        tenantIdType,
        tenantId,
        userId,
        lastScreenEvent,
        attributes
      } = context;
      const productKey = this._getProductKey(product, subproduct);
      if (this._shouldSendEvent(productKey, tenantIdType, tenantId, userId)) {
        this._setLastSentTimestamp(productKey, tenantId, userId);
        const event = this._createEvent(product, subproduct, isEmbeddedProduct, lastScreenEvent, attributes);
        this._onEvent(event);
      }
    });
    _defineProperty(this, "_createEvent", (product, subproduct, isEmbeddedProduct, lastScreenEvent, extraAttributes) => {
      const event = {
        product,
        source: 'ui',
        action: 'viewed',
        actionSubject: 'ui',
        attributes: extraAttributes
      };
      if (isEmbeddedProduct) {
        // Clear the subproduct and version as they don't relate to the embedded product
        event.subproduct = null;
        event.version = null;
        event.attributes = _objectSpread(_objectSpread({}, event.attributes), {}, {
          embeddedInEnv: this._productInfo.env,
          embeddedInProduct: this._productInfo.product,
          embeddedInSubproduct: subproduct,
          embeddedInVersion: this._productInfo.version,
          embeddedInOrigin: this._productInfo.origin,
          embeddedInPlatform: this._productInfo.platform
        });
      }
      if (lastScreenEvent) {
        event.attributes = _objectSpread(_objectSpread({}, event.attributes), {}, {
          lastScreenEvent: {
            name: lastScreenEvent.name,
            attributes: lastScreenEvent.attributes
          }
        });
      }
      return event;
    });
    if (!productInfo) {
      throw new Error('Missing productInfo');
    }
    if (!productInfo.product) {
      throw new Error('Missing productInfo.product');
    }
    if (!getContext) {
      throw new Error('Missing getContext callback');
    }
    if (typeof getContext !== 'function') {
      throw new Error('Invalid getContext, must be function');
    }
    if (!onEvent) {
      throw new Error('Missing onEvent callback');
    }
    if (typeof onEvent !== 'function') {
      throw new Error('Invalid onEvent, must be function');
    }
    this._productInfo = productInfo;
    this._product = productInfo.product;
    this._getContext = getContext;
    this._onEvent = onEvent;
    this._delay = settings.delay || DEFAULT_DELAY;
    this._throttle = settings.throttle || DEFAULT_THROTTLE;
    this._storageKey = settings.storageKey || DEFAULT_STORAGE_KEY;
    this._safeLocalStorage = new SafeLocalStorage({
      envPrefix: this._productInfo.env
    });
    this._focusListener = () => {
      this._startInterval();
    };
    this._blurListener = () => {
      this._stopInterval();
    };
  }
}