import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// @ts-ignore
import traverseISODate from '@segment/isodate-traverse';
export const buildContext = context => {
  return _objectSpread(_objectSpread({}, context.context), {}, {
    userAgent: navigator.userAgent
  });
};
export const prepareEventProperties = properties => {
  const propertiesWithoutUndefined = nestedRemoveUndefinedValues(properties);
  return traverseISODate(propertiesWithoutUndefined);
};

// This function will essentially deepClone as well
export const nestedRemoveUndefinedValues = obj => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  const clone = {};
  Object.keys(obj).forEach(key => {
    const value = getValueOfObject(obj, key);
    if (value !== undefined) {
      if (Array.isArray(value)) {
        clone[key] = value.map(i => nestedRemoveUndefinedValues(i));
      } else if (typeof value === 'object' && value !== null) {
        clone[key] = nestedRemoveUndefinedValues(value);
      } else {
        clone[key] = value;
      }
    }
  });
  return clone;
};

// Using ts-ignore everywhere urked me
const getValueOfObject = (obj, key) => {
  // @ts-ignore This is an object
  return obj[key];
};