export const NoIndexedDbErrorName = 'NoIndexedDbError';

/*
 Only to be used with window.indexeddb is undefined.
 This will prevent DbDelegator from logging errors, but will not prevent swapping to memoryDB
*/
export default class NoIndexedDbError extends Error {
  constructor() {
    super('window.indexeddb is undefined.');
    // Must reset the prototypes after calling super of builtin classes
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, NoIndexedDbError.prototype);
    this.name = NoIndexedDbErrorName;
  }
}