export var logger = {
  enabled: false,
  enable: function enable() {
    this.enabled = true;
  },
  log: function log() {
    if (this.enabled) {
      var _console;
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      // eslint-disable-next-line no-console
      (_console = console).log.apply(_console, ['BM log: '].concat(args));
    }
  },
  logCond: function logCond(condition) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }
    if (this.enabled && condition && args.length > 1) {
      this.log.apply(this, args);
    }
  }
};