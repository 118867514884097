import { getIfVisuallyHiddenChildren } from './get-if-visually-hidden-children';
export default function getIsOnlySingleIcon(_ref) {
  var children = _ref.children,
    iconBefore = _ref.iconBefore,
    iconAfter = _ref.iconAfter;
  if (getIfVisuallyHiddenChildren(children)) {
    return true;
  }
  if (children) {
    return false;
  }
  if (iconBefore && !iconAfter) {
    return true;
  }
  if (!iconBefore && iconAfter) {
    return true;
  }
  return false;
}