import { useCallback, useEffect, useRef } from 'react';
var getHookDeps = function getHookDeps(opts) {
  switch (opts.cleanup) {
    case 'next-effect':
      return undefined;
    case 'unmount':
    default:
      return [];
  }
};

/**
 * Will return request animation frame as a function which will clean itself up.
 */
export var useRequestAnimationFrame = function useRequestAnimationFrame() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    cleanup: 'unmount'
  };
  var frames = useRef([]);
  useEffect(function () {
    return function () {
      if (frames.current.length) {
        frames.current.forEach(function (id) {
          return cancelAnimationFrame(id);
        });
        frames.current = [];
      }
    };
    // We dynamically set this so we either clean up on the next effect - or on unmount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, getHookDeps(opts));
  return useCallback(function (handler) {
    var id = requestAnimationFrame(function (time) {
      frames.current = frames.current.filter(function (frameId) {
        return frameId !== id;
      });
      handler(time);
    });
    frames.current.push(id);
  }, []);
};

/**
 * Will return set  timeout as a function which will clean itself up.
 */
export var useSetTimeout = function useSetTimeout() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    cleanup: 'unmount'
  };
  var timeouts = useRef([]);
  useEffect(function () {
    return function () {
      if (timeouts.current.length) {
        timeouts.current.forEach(function (id) {
          return clearTimeout(id);
        });
        timeouts.current = [];
      }
    };
    // We dynamically set this so we either clean up on the next effect - or on unmount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, getHookDeps(opts));
  return useCallback(function (handler, timeout) {
    for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }
    var id = setTimeout.apply(void 0, [function () {
      timeouts.current = timeouts.current.filter(function (timeoutId) {
        return timeoutId !== id;
      });
      handler();
    }, timeout].concat(args));
    timeouts.current.push(id);
  }, []);
};